<template>
    <div class="dropdown-wrapper">
        <div class="dropdown">
            <config-item v-if="item" v-for="item in $store.getters.cart" :key="item.id" :item="item"/>
            <p v-else>There are currently no items in your custom controller configuration.</p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.dropdown-wrapper{
    max-height: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 3.25rem;
    transition: max-height 1s ease-in-out;
    .dropdown{
        width: 18rem;
        max-height: 50vh;
        overflow-y: scroll;
        background: linear-gradient(135deg, rgba(24, 24, 24, 1) 0%, rgba(0, 0, 0, .8) 100%);
        backdrop-filter: blur(40px);
        padding: 1.5rem 1rem;
        border-radius: .6rem;
        border: solid 1px #222222;
        p{
            font-size: .8rem;
            margin-bottom: unset;
        }
    }
}
@include media-breakpoint-down(lg){
    .dropdown-wrapper{
        display: none;
    }
}
</style>