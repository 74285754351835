<template>
    <section>
        <div class="container-fluid">
            <div class="row wrapper">
                <div class="col-xxl-4 col-md-5 customiser" ref="customiser">
                    <!-- <div class="prebuilt-diy">
                        <div class="selector active">
                            Prebuilt
                        </div>
                            <div class="selector">
                            DIY
                        </div>
                    </div> -->
                    <category :category="category" v-for="category in $store.getters.categories" :key="category" icon='http://cdn.gamenetics.com/images/elements/shell.svg'/>
                </div>

                <div :class="[$store.state.view == 1 ? '' : 'back', 'col-md-7 controller']">
                    <img class="background" :src="`https://cloud.papercrowns.com/image/upload/q_auto,c_fill,h_1080,w_1920/v1/${$store.state.backgrounds[$store.state.selectedBackground].image_path}`" alt="" v-if="$store.state.backgrounds?.length">
                    <div class="controller-wrapper">
                        <div class="front-wrapper">
                            <img :src="$store.state.selectedProduct == 1 ? 'https://cdn.gamenetics.com/options/xbox_series/1.png' : 'https://cdn.gamenetics.com/options/playstation_5/1.png'" onerror="this.style.display='none'">
                            <template v-for="visual in visualsView(1)" :key="visual">
                                <img :src="visual.image_cdn" alt="" onerror="this.style.display='none'">
                            </template>
                        </div>
                        <div class="back-wrapper">
                            <img :src="$store.state.selectedProduct == 1 ? 'https://cdn.gamenetics.com/options/xbox_series/2.png' : 'https://cdn.gamenetics.com/options/playstation_5/2.png'" onerror="this.style.display='none'">
                            <template v-for="visual in visualsView(2)" :key="visual">
                                <img :src="visual.image_cdn" alt="" onerror="this.style.display='none'">
                            </template>
                        </div>
                    </div>
                    <div class="controls">
                        <div :class="[$store.state.view == 1 ? 'rotate' : '','control view']" @click="toggleView()">
                            <img src="https://cdn.gamenetics.com/images/elements/rotate.svg" alt="">
                            <i class="fad fa-sync"></i>
                        </div>
                        <div class="control showcase" @click="toggleViewModal(true)">
                            <img src="https://cdn.gamenetics.com/images/elements/rotate.svg" alt="">
                            <i class="far fa-eye"></i>
                        </div>
                        <div class="control product">
                            <img src="https://cdn.gamenetics.com/images/elements/rotate.svg" alt="">
                            <select name="" id="" @change="changeProduct($event)">
                                <option :value="product.id" v-for="product in $store.state.products" :key="product.id" :selected="$store.state.selectedProduct == product.id">{{ product.name_short }}</option>
                            </select>
                            <i :class="$store.state.product.icon"></i>
                        </div>
                        <div class="control background">
                            <img src="https://cdn.gamenetics.com/images/elements/rotate.svg" alt="">
                            <select name="" id=""  @change="changeBackground($event)">
                                <option v-for="background, i in $store.state.backgrounds" :key="background.name" :value="i" :selected="$store.state.selectedBackground == i">{{ background.name }}</option>
                            </select>
                            <i class="fas fa-image"></i>
                        </div>
                        <!-- <div>
                            Backgrounds:
                            <div v-for="background, i in $store.state.backgrounds" :key="background.name" @click="changeBackground(i)">
                                {{ background.name }} - <img :src="`https://cloud.papercrowns.com/image/upload/q_auto,c_fill,h_250,w_250/v1/${background.image_path}`" alt="">
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div :class="[viewModal ? 'active' : '', 'view-modal']">
        <div class="close" @click="toggleViewModal(false)">
            <i class="far fa-times"></i>
        </div>
        <div class="controller-wrapper">
            <div class="front-wrapper">
                <img :src="$store.state.selectedProduct == 1 ? 'https://cdn.gamenetics.com/options/xbox_series/1.png' : 'https://cdn.gamenetics.com/options/playstation_5/1.png'" onerror="this.style.display='none'">
                <template v-for="visual in visualsView(1)" :key="visual">
                    <img :src="visual.image_cdn" alt="" onerror="this.style.display='none'">
                </template>
            </div>
            <div class="back-wrapper">
                <img :src="$store.state.selectedProduct == 1 ? 'https://cdn.gamenetics.com/options/xbox_series/2.png' : 'https://cdn.gamenetics.com/options/playstation_5/2.png'" onerror="this.style.display='none'">
                <template v-for="visual in visualsView(2)" :key="visual" onerror="this.style.display='none'">
                    <img :src="visual.image_cdn" alt="">
                </template>
            </div>
        </div>
    </div>
    <div class="building" v-if="building">
        <i class="fas fa-cog"></i>
        <h3>{{ preparingCart ? 'Preparing Your Cart' : 'Building Controller' }}</h3>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default{
    data() {
        return {
            viewModal: false,
        };
    },
    mounted(){

    },
    computed: {
        ...mapState(['config','building','preparingCart']),
        ...mapGetters(['visuals']),
    },
    watch: {
        config(newValue, oldValue) {

        },
    },
    methods: {
        toggleViewModal:function(state){
            this.viewModal = state;
        },
        visualsView: function (view){
            return this.visuals.length ? _.orderBy(this.visuals.filter(visual => {
                return visual.view == view;
            }), 'sorting') : []
        },
        toggleView:function(){
            this.$store.commit('toggleView');
        },
        changeProduct(event){
            this.$store.commit('setSelectedProduct',event.target.value);
        },
        changeBackground(event){
            this.$store.commit('setSelectedBackground',event.target.value);
        },
    }
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.fade-enter-active, .fade-leave-active {
    transition-duration: 0.2s;
    transition-property: opacity;
}
.fade-enter, .fade-leave-active {
    opacity: 0
}
section{
    overflow: hidden;
    .wrapper{
        .customiser{
            height: 100vh;
            padding: 8rem 1.5rem 1.5rem 0;
            overflow-y: scroll;
            background: rgba(0, 0, 0, .5);
            backdrop-filter: blur(20px);
            max-width: 35rem;
            // Scrollbar
            &::-webkit-scrollbar {
                padding: 1.5rem 0;
                width:6px;
            }
            &::-webkit-scrollbar-track {
                background:rgba(0, 0, 0, .25);
            }
            &::-webkit-scrollbar-thumb {
                background:rgb(138, 43, 226);
                border-radius:10px;
            }
            &::-webkit-scrollbar-track-piece:start{
                margin-top: 6rem;
            }
            &::-webkit-scrollbar-track-piece:end{
                margin-bottom: 1.5rem;
            }
            .prebuilt-diy{
                padding: 1rem;
                display: none;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 1rem;
                background: linear-gradient(135deg, rgba(24, 24, 24, 0.5) 0%, rgba(0, 0, 0, 0.1) 100%);
                backdrop-filter: blur(40px);
                border-radius: 10px;
                border: solid 1px #222222;
                .selector{
                    font-size: .7rem;
                    letter-spacing: .06rem;
                    text-transform: uppercase;
                    font-weight: 600;
                    width: 48%;
                    background: #290C45;
                    color: $accent;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: .5rem;
                    border-radius: .2rem;
                    transition: all .3s ease-in-out;
                    &.active{
                        background: $accent;
                        color: #fff;
                    }
                }
            }
        }
        .controller{
            position: relative;
            height: calc(100vh);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
                img.background {
                    position: absolute;
                    z-index: -1;
                    left: -24.5rem;
                    background: $background-color;
                    background-size: cover;
                    object-fit: cover;
                    background-position: center;
                    width: calc(100% + 50rem);
                    height: 100vh;
                }
            .controls{
                display: flex;
                position: relative;
                z-index: 2;
                .control{
                    cursor: pointer;
                    margin-right: 1.5rem;
                    position: relative;
                    height: 4rem;
                    width: 4rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                    }
                    i{
                        font-size: 1.5rem;
                        color: #fff;
                        position: relative;
                        &::before, &::after{
                            transition: opacity .3s ease;
                        }
                    }
                    &:last-child{
                        margin-right: unset;
                    }
                    &.view{
                        i{
                            &::before{
                                opacity: .5;
                            }
                            &::after{
                                opacity: 1;
                            }
                        }
                        &.rotate{
                            i{
                                &::before{
                                    opacity: 1;
                                }
                                &::after{
                                    opacity: .5;
                                }
                            }
                        }
                    }
                    &.product,&.background{
                        display: none;
                        i{
                            opacity: 1;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                        select{
                            position: relative;
                            z-index: 1;
                            background: unset;
                            appearance: unset;
                            border: unset;
                            outline: unset;
                            width: 100%;
                            height: 100%;
                            opacity: 0;
                        }
                    }
                    &.background {
                        display: block;
                    }
                }
            }
            &.back{
                .controller-wrapper{
                    .front-wrapper{
                        z-index: 1;
                        bottom: unset;
                        top: -2.5rem;
                        transform: scale(.9);
                        filter: brightness(50%);
                    }
                    .back-wrapper{
                        z-index: 2;
                        top: unset;
                        top: 1rem;
                        transform: scale(1);
                        filter: brightness(100%);
                    }
                }
            }
        }
    }
}
.controller-wrapper{
    width: 100%;
    position: relative;
    .front-wrapper{
        transition: all 0.2s;
        position: relative;
        width: 100%;
        padding-top: 56.25%;
        transform: scale(1);
        filter: brightness(100%);
        z-index: 2;
        top: 1rem;
        img{
            position: absolute;
            object-fit: contain;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
    .back-wrapper{
        transition: all 0.2s;
        position: absolute;
        width: 100%;
        padding-top: 56.25%;
        transform: scale(.9);
        filter: brightness(50%);
        z-index: 1;
        top: -2.5rem;
        img{
            position: absolute;
            object-fit: contain;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
}
.view-modal{
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: .9);
    backdrop-filter: blur(8px);
    justify-content: center;
    align-items: center;
    display: none;
    &.active{
        display: flex;
    }
    .controller-wrapper{
        display: flex;
        .front-wrapper, .back-wrapper{
            position: relative;
            top: unset;
            bottom: unset;
            filter: unset;
            transform: scale(1);
        }
    }
    .close{
        cursor: pointer;
        position: absolute;
        z-index: 2;
        right: 2rem;
        top: 6rem;
        i{
            font-size: 1.5rem;
        }
    }
}
.building{
    position: absolute;
    z-index: 101;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba($color: #000000, $alpha: .9);
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: center;
    align-items: center;
    i{
        font-size: 2rem;
        margin-right: 1.5rem;
        animation: gear infinite 3s;
        animation-timing-function: linear;
        color: $accent;
    }
    h3{
        margin: unset;
    }
    @keyframes gear{
        from{
            transform: rotate(0deg);
        }
        to{
            transform: rotate(360deg);
        }
    }
}
@include media-breakpoint-up(xl){
    section{
        .wrapper{
            justify-content: space-between;
        }
    }
}
@include media-breakpoint-down(md){
    section{
        .wrapper{
            flex-direction: column-reverse;
            .controller{
                height: 60vh;
                padding-top: 6rem;
                .controller-wrapper{
                    width: 65vw;
                }
                .controls{
                    .control{
                        margin-right: .5rem;
                        height: 3rem;
                        width: 3rem;
                        i{
                            font-size: 1rem;
                        }
                        &.showcase{
                            display: none;
                        }
                        &.product{
                            display: flex;
                        }
                        &:hover{
                            transform: unset;
                        }
                    }
                }
            }
            .customiser{
                padding: unset;
                height: 40vh;
                max-width: unset;
                .prebuilt-diy{
                    display: flex;
                }
                &::-webkit-scrollbar {
                    padding: 1.5rem 0;
                    width:6px;
                }
                &::-webkit-scrollbar-track-piece:start{
                    margin-top: unset;
                }
                &::-webkit-scrollbar-track-piece:end{
                    margin-bottom: unset;
                }
            }
        }
    }
}
@include media-breakpoint-down(sm){
    section{
        .wrapper{
            .controller{
                height: 50vh;
            }
            .customiser{
                height: 50vh;
            }
        }
    }
}
@media screen and (max-width: 440px){
    section{
        .wrapper{
            .controller{
                .controller-wrapper{
                    width: 80vw;
                }
            }
        }
    }
}
</style>
