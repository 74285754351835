<template>
   <footer>
      <div class="container-fluid">
         <div class="row wrapper">
            <div class="col-sm-4">
               <ul>
                  <p class="heading">Explore</p>
                  <!-- <li><router-link to="builder">Customize</router-link></li> -->
                  <li><a href="https://shop.gamenetics.com/">Shop</a></li>
                  <li><a href="https://shop.gamenetics.com/pages/content">Content</a></li>
                  <li><a href="https://shop.gamenetics.com/pages/story">Story</a></li>
                  <li><a href="https://shop.gamenetics.com/pages/faqs">FAQs</a></li>
               </ul>
            </div>
            <div class="col-sm-4">
               <ul>
                  <p class="heading">Products</p>
                  <li><a href="https://shop.gamenetics.com/collections/xbox">Playstation 4</a></li>
                  <li><a href="https://shop.gamenetics.com/collections/playstation">Playstation 5</a></li>
                  <li><a href="https://shop.gamenetics.com/collections/playstation">Pro Controllers</a></li>
                  <li><a href="https://shop.gamenetics.com/collections/playstation">RGB LED Transparent</a></li>
                  <li><a href="https://shop.gamenetics.com/collections/playstation">Used PS4</a></li>
                  <li><a href="https://shop.gamenetics.com/collections/playstation">Used XBOX One</a></li>
                  <li><a href="https://shop.gamenetics.com/collections/playstation">XBOX Series X|S</a></li>
               </ul>
            </div>
            <div class="col-sm-4">
               <ul>
                  <p class="heading">Information</p>
                  <li><a href="https://shop.gamenetics.com/policies/privacy-policy">Privacy Policy</a></li>
                  <li><a href="https://shop.gamenetics.com/policies/refund-policy">Refund Policy</a></li>
                  <li><a href="https://shop.gamenetics.com/policies/terms-of-service">Terms of Service</a></li>
                  <li><a href="https://shop.gamenetics.com/policies/shipping-policy">Shipping Policy</a></li>
               </ul>
            </div>
         </div>
         <div class="company">
            <div class="leading">
               <span>info@gamenetics.com</span>
               <span>Gamenetics<br>1627 Union Street<br>Bangor ME 04401</span>
            </div>
            <div class="trailing">
               <span>Oakley Kilgour &amp; Cooper Kilgour SP</span>
            </div>
         </div>
      </div>
   </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
footer{
   padding: 2.5rem 0;
   font-size: .8rem;
   border-top: solid 1px #222;
   background: #000;
   // margin-top: 5rem;
   position: relative;
   z-index: 1;
   .wrapper{
      p.heading{
         text-transform: uppercase;
         margin-bottom: 1.5rem;
         letter-spacing: .06rem;
      }
      ul{
         list-style-type: none;
         margin: unset;
         padding: unset;
         li{
            padding: unset;
            margin: unset;
            margin-bottom: .75rem;
            &:last-child{
               margin-bottom: unset;
            }
            a{
               text-decoration: none;
               color: #8E8E8E;
            }
         }
      }
      form{
         input{
            width: 100%;
            background: unset;
            outline: unset;
            border: unset;
            border-bottom: solid 1px #8E8E8E;
            margin-bottom: 1rem;
            padding: .75rem 0;
            color: #fff;
            &::placeholder{
               color: #343434;
            }
         }
         button{
            width: 100%;
            text-align: center;
            color: #fff;
            background: $accent;
            outline: unset;
            border: unset;
            text-transform: uppercase;
            font-weight: 400;
            letter-spacing: .06rem;
            padding: .75rem 0;
         }
      }
   }
   .company{
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;
      .leading{
         span{
            margin-bottom: 1rem;
            &:last-child{
               margin-bottom: unset;
            }
         }
         display: flex;
         flex-direction: column;
      }
   }
}
@include media-breakpoint-down(sm){
   footer{
      .wrapper{
         gap: 2rem;
      }
      .company{
         flex-direction: column;
         .leading{
            margin-bottom: 1rem;
         }
      }
   }
}
</style>
