<template>
    <input :type="type || 'text'" :placeholder="placeholder" :maxlength="max" :required="required" v-on="handlers" :value="modelValue" :disabled="disabled" ref="input">
</template>

<script>
export default {
    props: ['modelValue','filter','type','required','placeholder','disabled','max'],
    data(){
        return{
            ov: null,
            oss: null,
            ose: null,
            handlers: {
                input: this.input,
                keyup: this.input,
                keydown: this.input,
                select: this.input,
                contextmenu: this.input,
                drop: this.input
            }
        }
    },
    methods: {
        input(event){
            var input = this.$refs.input;
            if(this.filter == 'username'){
                if (/^[a-zA-Z0-9_ ]*$/i.test(input.value)) {
                    this.ov = input.value
                    .replace(/-+/g, '')
                    // .replace(/\s+/g, '_')
                    .replace(/\_\_+/g, '')
                    .replace(/^_+/, '');
                    this.oss = input.selectionStart;
                    this.ose = input.selectionEnd;
                    input.value = this.ov;
                    this.$emit('update:modelValue', this.ov);
                } else if (this.ov) {
                    input.value = this.ov;
                    this.$emit('update:modelValue', this.ov);
                    input.setSelectionRange(this.oss, this.ose);
                } else {
                    input.value = '';
                    this.$emit('update:modelValue', '');
                }
            }else if(this.filter == 'phone'){
                if (/^[0-9+()-]*$/i.test(input.value)) {
                    this.ov = input.value
                    .replace(/\s+/g, '-')
                    .replace(/\-\-+/g, '-')
                    .replace(/^-+/, '');
                    this.oss = input.selectionStart;
                    this.ose = input.selectionEnd;
                    input.value = this.ov;
                    this.$emit('update:modelValue', this.ov);
                } else if (this.ov) {
                    input.value = this.ov;
                    this.$emit('update:modelValue', this.ov);
                    input.setSelectionRange(this.oss, this.ose);
                } else {
                    input.value = '';
                    this.$emit('update:modelValue', '');
                }
            }else{
                this.$emit('update:modelValue', event.target.value);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
</style>
