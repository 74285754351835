<template>
<div class="tab">
    <div class="heading" @click="expanded = !expanded" role="button">
        <div class="wrapper">
            <div class="leading">
                <img src="https://gamenetics.s3.amazonaws.com/images/elements/shell.svg">
                <h2>{{ category.name }}</h2>
            </div>
            <div class="arrow">
                <i :class="['fas fa-chevron-down',{'active':expanded}]"></i>
            </div>
        </div>
    </div>
    <div v-if="expanded">
        <div class="options">
            <template v-for="option, i in category.options" :key="option">
                <option-style v-if="option.type_id == 1" :option="option"/>
                <option-boolean v-if="option.type_id == 2" :option="option"/>
                <option-thumbsticks v-if="option.type_id == 3" :option="option"/>
                <option-text v-if="option.type_id == 4" :option="option"/>
                <!-- <option-rmb v-if="option.type_id == 4" :option="option"/> -->
                <div v-if="!lastOption(i)" class="divider"></div>
            </template>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ['category', 'icon'],
    data() {
        return {
            expanded: false
        }
    },
    methods: {
        lastOption:function(i) {
            return this.category ? i+1 == Object.keys(this.category.options).length : false;
        },
    }
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.tab{
    background: linear-gradient(135deg, rgba(24, 24, 24, 0.5) 0%, rgba(0, 0, 0, 0.1) 100%);
    backdrop-filter: blur(40px);
    border-radius: 10px;
    border: solid 1px #222222;
    color: #fff;
    margin-bottom: 2rem;
    padding: 1.5rem 1.5rem;
    &:last-child{
        margin-bottom: unset;
    }
    .heading{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .leading {
                display: flex;
                align-items: center;
                img{
                    margin-right: 1rem;
                    height: 1.5rem;
                }
                h2{
                    margin: unset;
                    font-size: 1.5rem;
                }
            }
            .arrow {
                width: 2rem;
                height: 2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: auto;
                border: solid 1px #222222;
                border-radius: 0.5rem;
                i {
                    &.active {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    .options {
        padding-top: 2rem;
    }
    .divider{
        height: 0.1rem;
        width: 100%;
        background: #222222;
        margin: 2rem 0
    }
}
@include media-breakpoint-down(md){
    .tab{
        margin-bottom: 1rem;
        padding: 1rem;
        .heading{
            .wrapper{
                img{
                    height: 1.2rem;
                    margin-right: .75rem;
                }
                h2{
                    font-size: 1.2rem;
                }
            }
        }
    }
}
</style>
