<template>
    <div class="title">
        <p @click="test()">Remappable Buttons?</p>
        <div class="info">
            <i class="fal fa-question-circle"></i>
        </div>
    </div>
    <div class="options">
        <gn-button :class="configButtons.length ? 'active' : ''" @click="toggleRemappable(true).then(scroll)">Include</gn-button>
        <gn-button :class="configButtons.length ? '' : 'active'" @click="toggleRemappable(false)">Remove</gn-button>
    </div>
    <div class="remappable-options" v-if="configButtons.length">
        <div class="amount">
            <p>How many buttons?</p>
            <div class="wrapper">
                <div :class="[configButtons.length==i+1 ?'active':'','sq-button']" v-for="button, i in buttonOptions" :key="button.slug" @click="changeAmount(i+1)">{{ i+1 }}</div>
            </div>
        </div>
        <div class="button" v-for="button, i in configButtons" :key="button">
            <p>Button {{ i+1 }}</p>
            <div class="selectors">
                <div class="select-wrapper">
                    <select name="" id="" @input="e => setButtonPosition(i,e.target.value)">
                        <option :value="configButtons[i].position">{{ buttonAttribute(configButtons[i].position).name }}</option>
                        <option :value="position" v-for="position in unusedPositions" :key="position">{{ buttonAttribute(position).name }}</option>
                    </select>
                    <i class="fas fa-caret-down"></i>
                </div>
                <div class="select-wrapper">
                    <select name="" id="" v-model="configButtons[i].action">
                        <option value="a">A</option>
                        <option value="b">B</option>
                        <option value="x">X</option>
                        <option value="y">Y</option>
                    </select>
                    <i class="fas fa-caret-down"></i>
                </div>
            </div>
            <div class="variants row row-cols-5">
                <swatch v-for="swatch in buttonAttribute(button.position).swatches" :key="swatch.id" :option="option" :swatch="swatch" :selected="false" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['option'],
    computed: {
        config(){
            return this.$store.state.config[this.option.slug];
        },
        configButtons: {
            get() {
                return this.$store.state.config[this.option.slug].selection.buttons
            },
            set(value) {
                this.$store.commit('setConfigSelection', {
                    option: this.option.slug,
                    name: 'buttons',
                    value: value
                });
            }
        },
        buttonOptions(){
            return this.option.buttons ? this.option.buttons : [];
        },
        positions(){
            return this.buttonOptions.reduce(function(positions, button){
                positions.push(button.slug);
                return positions;
            }, []);
        },
        selectedPositions(){
            return this.configButtons.reduce(function(positions, button){
                positions.push(button.position);
                return positions;
            }, [])
        },
        unusedPositions(){
             return this.positions.filter(p => !this.selectedPositions.includes(p));
        },

    },
    methods: {
        test:function(){
            this.$store.commit('setConfigSelection', {
                option: this.option.slug,
                name: 'buttons',
                value: []
            });
        },
        toggleRemappable:function(bool){
            return new Promise((resolve, reject) => {
                if(bool){
                    if(!this.configButtons.length){
                        this.configButtons = [
                            {
                                position: this.buttonOptions[0].slug,
                                action: 'a',
                                swatch: this.buttonOptions[0].swatches[0].id
                            },
                            // {
                            //     position: 'top_right',
                            //     action: 'a',
                            //     swatch: 1
                            // },
                            // {
                            //     position: 'bottom_right',
                            //     action: 'a',
                            //     swatch: 1
                            // }
                        ];
                    }
                }else{
                    this.configButtons = [];                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              9
                }
                resolve();
            });
        },
        buttonAttribute:function(position){
            return this.buttonOptions.filter(function(button){ return button.slug == position; }) ? this.buttonOptions.filter(function(button){ return button.slug == position; })[0] : [];
        },
        setButtonPosition:function(button, position){
            this.$store.commit('setButtonPosition', [this.option.slug, button, position]);
        },
        changeAmount:function(amount){
            var diff = Math.abs(amount-this.configButtons.length);
            if(amount > this.configButtons.length){
                console.log('Adding '+diff+' Buttons');
                // More Buttons
            }else if(amount < this.configButtons.length){
                for (var i = 0; i < diff; i++){
                    this.configButtons.pop()
                }
            }
        },
        scroll(){
            let customiser = this.$parent.$parent.$refs.customiser;
            customiser.scrollTop = customiser.scrollHeight;
        }
    }
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    p{
        margin-bottom: unset;
    }
    i{
        color: #8B859E;
    }
}
.options{
    display: flex;
    align-items: center;
    .button{
        margin-right: 1rem;
        &:last-child{
            margin-right: unset;
        }
    }
}
.remappable-options{
    margin-top: 3rem;
    .amount{
        margin-bottom: 3rem;
        .wrapper{
            display: flex;
            align-items: center;
            .sq-button{
                margin-right: 1rem;
                background: #290C45;
                color: $accent;
                transition: all .3s ease-in-out;
                &:last-child{
                    margin-right: unset;
                }
                &.active{
                    background: $accent;
                    color: #fff;
                }
            }
        }
    }
    .button{
        .selectors{
            display: flex;
            align-items: center;
            .select-wrapper{
                position: relative;
                margin-right: 1rem;
                &:last-child{
                    margin-right: unset;
                }
                select{
                    position: relative;
                    background: $accent;
                    padding: .45rem 2rem .45rem 1rem;
                    color: white;
                    font-size: .7rem;
                    letter-spacing: .06rem;
                    text-transform: uppercase;
                    font-weight: 600;
                    border-radius: .2rem;
                    appearance: none;
                    outline: unset;
                    border: unset;
                }
                i{
                    position: absolute;
                    right: .75rem;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: .85rem;
                }
            }
        }
        .variants{
            margin-top: 3rem;
        }
    }
}
</style>
