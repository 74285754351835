<template>
    <a :href="href" v-if="href" :class="[{inactive: inactive},'button']">
        <div class="leading"></div>
        <div class="middle"><slot></slot></div>
        <div class="trailing"></div>
    </a>

    <router-link v-else-if="to" :to="{ name: to, hash: hash }" :class="[{inactive: inactive},'button']">
        <div class="leading"></div>
        <div class="middle"><slot></slot></div>
        <div class="trailing"></div>
    </router-link>

    <div v-else :class="[{inactive: inactive},'button']">
        <div class="leading"></div>
        <div class="middle"><slot></slot></div>
        <div class="trailing"></div>
    </div>
</template>

<script>
export default {
    props: ['inactive','href','to','hash']
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.button{
    display: flex;
    cursor: pointer;
    text-decoration: unset;
    .leading, .middle, .trailing{
        position: relative;
        transition: background 0.2s;
        background: $accent;
    }
    .leading{
        left: .35px;
        width: .5rem;
        clip-path: polygon(100% 0, 0 50%, 100% 100%);
    }
    .middle{
        padding: .45rem 1rem;
        color: white;
        font-size: .7rem;
        letter-spacing: .06rem;
        text-transform: uppercase;
        font-weight: 600;
        border-radius: unset;
        white-space: nowrap;
    }
    .trailing{
        right: .3px;
        width: .5rem;
        clip-path: polygon(0 0, 100% 50%, 0 100%);
    }
    &:hover{
        .leading, .middle, .trailing{
            background: darken($accent, 10%);
        }
    }
    &.large{
        .middle{
            width: 100%;
        }
        .leading{
            left: .15px;
        }
    }
    &.inactive{
        .leading,.middle,.trailing{
            background: grey;
        }
    }
}
</style>
