<template>
    <div class="col-lg-4 wrapper">
        <div class="package">
            <div class="heading">
                <p class="highlight">Standard</p>
                <h2>Free of Charge</h2>
            </div>
            <div class="desc">
                <p>Nunc magna accumsan id elit vestibulum a lorem consequat scelerisque adipiscing fermentum ut risus, egestas urna purus phasellus duis velit nisl ac tristique.</p>
            </div>
            <ul>
                <li>
                    Bullet Point One
                </li>
                <li>
                    Bullet Point One
                </li>
                <li>
                    Bullet Point One
                </li>
                <li>
                    Bullet Point One
                </li>
                <li>
                    Bullet Point One
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.wrapper{
    .package{
        padding: 2rem;
        width: 100%;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
        backdrop-filter: blur(6px);
        border-radius: .6rem;
        border: 1px solid #262626;
        .heading{
            margin-bottom: 2rem;
            p{
                margin-bottom: .5rem;
            }
            h2{
                text-transform: uppercase;
            }
        }
        .desc{
            margin-bottom: 2rem;
        }
        ul{
            list-style-type: none;
            margin: unset;
            padding: unset;
            li{
                position: relative;
                padding-left: 1.5rem;
                margin-bottom: 1rem;
                &::marker{
                    display: none;
                }
                &::before{
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    width: .5rem;
                    height: .5rem;
                    background: $accent;
                    clip-path: polygon(25% 10%, 75% 10%, 100% 50%, 75% 90%, 25% 90%, 0% 50%);
                }
            }
        }
    }
}
@include media-breakpoint-down(lg) {
    .wrapper{
        margin-bottom: 2rem;
    }
}
</style>