<template>
    <div class="selector">
        <div class="popper-wrapper">
            <Popper arrow>
                <i class="fal fa-info-circle"></i>
                <template #content>
                    <div>{{ option.description }}</div>
                </template>
            </Popper>
        </div>
        <div class="title">
            <div class="text-wrapper">
                <h3>{{ option.name }}</h3>
                <p>{{ config.selection.name }} <template v-if="!config.selection.default">+{{ value }}</template></p>
            </div>
        </div>
        <div class="select-wrapper">
            <Input type="text" :disabled="confirmed" v-model="text" filter="username" max="12"/>
            <button @click="editText([option.id,text]),confirmed = true" v-if="!confirmed">Confirm</button>
            <template v-else>
                <button class="btn btn-primary" @click="confirmed = false">Edit</button>
                <button class="btn btn-primary" @click="editText([option.id,null]),confirmed = false,text = null">Remove</button>
            </template>
            <!-- <gn-button v-for="select in option.selects" :key="select.id" @click="editSelect([option.id,select.id])">{{ select.name }}</gn-button> -->
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Popper from "vue3-popper";
export default {
    components: {
        Popper,
    },
    props: ['option'],
    data() {
        return {
            confirmed: false,
            text: null
        }
    },
    computed: {
        ...mapState(['config']),
        config(){
            return this.$store.state.config[this.option.slug];
        },
        value(){
            return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            }).format(this.config.value);
        },
    },
    methods: {
        ...mapActions([
            'editText'
        ])
    }
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

:deep(.popper) {
    background: #2C273D;
    width: 100vw;
    max-width: 20rem;
    color: #fff;
    padding: .75rem;
    font-size: .825rem;
    border-radius: .5rem;
    header{
        font-weight: 700;
        margin-bottom: .35rem;
    }
    p{
        margin-bottom: unset;
    }
}

:deep(.popper #arrow::before) {
    background: #2C273D;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
    background: #2C273D;
}
@include media-breakpoint-down(md){
    :deep(.popper) {
        width: 85vw;
        max-width: unset;
    }
}

.selector{
    position: relative;
    .popper-wrapper{
        position: absolute;
        top: 0;
        right: 0;
        i{
            color: #8B859E;
        }
    }
    .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-items: flex-start;
        margin-bottom: 2rem;
        .text-wrapper{
            h3{
                font-size: 1rem;
                margin-bottom: unset;
            }
            p{
                margin: unset;
                color: $highlight;
                font-size: .8rem;
            }
        }
    }
    .select-wrapper{
        flex-wrap: wrap;
        display: flex;
        gap: .25rem;
        input {
            flex-grow: 1;
            &:focus {
                outline: none;
                border: solid 1px $accent;
            }
        }
        button {
            background: $accent;
            color: white;
            padding: .45rem 1rem;
            font-size: .7rem;
            border-radius: 0.2rem;
            border: unset;
            outline: unset;
            text-transform: uppercase;
            font-weight: 700;
        }
    }
}
@include media-breakpoint-down(md){
    .selector{
        .title{
            margin-bottom: 1rem;
        }
    }
}
</style>
