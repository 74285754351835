<template>
   <a :href=post.link target="_blank" class="individual-content">
      <div :class="[post.platform.slug, 'content-wrapper']">
         <div class="image">
            <img src="https://cdn.gamenetics.com/images/thumbnail.jpg" alt="">
         </div>
         <div class="details">
            <h3>{{ post.title }}</h3>
            <p class="highlight"><template v-for="tag in post.tags" :key="tag.slug">{{ tag.name }} / </template></p>
         </div>
         <div class="icon youtube">
            <i class="fab fa-youtube"></i>
         </div>
      </div>
   </a>
</template>

<script>
export default{
   props: ['post']
}
</script>

<style lang="scss" scoped>
a.individual-content{
   display: flex;
   flex-direction: column;
   flex-grow: 1;
   margin-bottom: 2rem;
   color: #fff;
   text-decoration: unset;
   .content-wrapper{
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      position: relative;
      cursor: pointer;
      .image{
         position: relative;
         width: 100%;
         border-radius: .4rem;
         overflow: hidden;
         img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            transition: all .5s ease;
         }
      }
      .details{
         position: relative;
         padding: 1rem;
         border: 1px solid #222;
         border-radius: .4rem;
         background: linear-gradient(135deg, rgba(24, 24, 24, 0.5) 0%, rgba(0, 0, 0, 0.1) 100%);
         display: flex;
         flex-direction: column;
         flex-grow: 1;
         &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(135deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 100%);
            border-radius: .4rem;
            opacity: 0;
            transition: opacity .4s ease;
        }
         h3{
            position: relative;
            z-index: 1;
            text-transform: unset;
            margin-bottom: 1rem;
         }
         p{
            position: relative;
            z-index: 1;
            margin-bottom: unset;
         }
      }
      .icon{
         background: linear-gradient(135deg, rgba(27, 27, 27, 1) 0%, rgba(0, 0, 0, 1) 100%);
         border: 1px solid #222;
         border-radius: .4rem;
         position: absolute;
         z-index: 2;
         top: .5rem;
         right: .5rem;
         width: 2.5rem;
         height: 2.5rem;
         display: flex;
         align-items: center;
         justify-content: center;
         i{
            font-size: 1.25rem;
         }
         &.youtube{
            background: red;
         }
         &.instagram{
            background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
         }
      }
      &.instagram{
         .image{
            padding-top: 100%;
         }
         .details{
            display: none;
         }
      }
      &.twitter{
         .image{
            padding-top: 56.25%;
         }
         .details{
            display: block;
         }
      }
      &.youtube{
         .image{
            padding-top: 56.25%;
         }
         .details{
            display: block;
         }
      }
      &:hover{
         .image{
            img{
               transform: scale(1.015);
            }
         }
         .details{
            &:before{
               opacity: .2;
            }
         }
      }
   }
}
</style>
