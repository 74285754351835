<template>
    <div class="faq">
        <div class="faq-heading">
            <h4>Massa, dui egestas nunc, ornare dui, netus viverra cursus sit adipiscing quis</h4>
            <div class="trigger">
                <i class="far fa-chevron-down"></i>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-10 faq-body">
                <p>Fusce blandit est enim arcu dui vel, vel nunc quisque elementum duis tempus velit lectus feugiat facilisis augue tellus urna adipiscing mauris ultrices viverra amet augue convallis cursus cursus convallis velit, eget sed dignissim auctor maecenas etiam at augue elit at nunc accumsan proin lacus amet, amet a ullamcorper posuere cras cras aliquet eleifend elementum vel aliquet tristique fusce nibh rutrum iaculis consequat ut</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.faq{
    padding: 2rem;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
    border: 1px solid #262626;
    border-radius: .6rem;
    margin-bottom: 1.5rem;
    cursor: pointer;
    &:last-child{
        margin-bottom: unset;
    }
    .faq-heading{
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h2{
            font-size: 1.5rem;
            max-width: 85%;
        }
        .trigger{
            i{
                font-size: 1.2rem;
                padding: .5rem;
                background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
                border: 1px solid #262626;
                border-radius: .2rem;
            }
            &.active{
                i{
                    transform: rotate(180deg);
                }
            }
        }
    }
    .faq-body{

    }
}
</style>