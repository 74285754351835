<template>
  <div :class="[selected ? 'selected' : '', 'swatch col']" @mouseover="setPreview([option.id,swatch.id])" @mouseleave="resetPreview()">
    <div class="color" :style="{ 'background': 'url('+swatch.image+')' }" v-if="swatch.image"></div>
    <div class="color" :style="{ background: swatch.hex }" v-else></div>
    <p>{{ swatch.name }}</p>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    props: ['option','swatch','selected'],
    methods: {
        ...mapActions([
            'setPreview',
            'resetPreview'
        ])
    },
}
</script>

<style lang="scss" scoped>
@import "./resources/sass/_variables.scss";
.swatch{
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;
    &:nth-child(n + 6){
        margin-bottom: unset;
    }
    .color{
        position: relative;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        margin-bottom: .5rem;
        transition: all .2s ease-in-out;
        background-position: center !important;
        background-size: cover !important;
    }
    p{
      font-size: .65rem;
      line-height: 1rem;
      color: #fff;
      max-height: 1rem;
    }
    &.selected{
        .color{
            box-shadow: inset 0 0 0 2px #000;
            border: 2px solid $accent;
        }
    }
}
</style>
