<template>
    <div class="config-item">
        <img src="https://cdn.gamenetics.com/images/elements/shell.svg" alt="">
        <div class="wrapper">
            <div class="product-details">
                <p>{{ item.name }}</p>
                <p>{{ item.selection.name }}</p>
            </div>
                <p>+${{ item.value }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: ['item']
}
</script>

<style lang="scss" scoped>
.config-item{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: solid 1px #222222;
    &:last-child{
        margin-bottom: unset;
        padding-bottom: unset;
        border-bottom: unset;
    }
    img{
        margin-right: 1.5rem;
        height: 1.5rem;
    }
    .wrapper{
        display: flex;
        flex-grow: 1;
        align-items: flex-start;
        justify-content: space-between;
        .product-details{
            p{
                line-height: 1.4rem;
                color: #fff;    
                &:first-child{
                    font-weight: 700;
                }
            }
        }
        p{
            font-size: .85rem;
            margin: unset;
            color: #858585;
        }
    }
}
</style>