<template>
    <div class="selector">
        <div class="popper-wrapper">
            <Popper arrow>
                <i class="fal fa-info-circle"></i>
                <template #content>
                    <div>{{ option.description }}</div>
                </template>
            </Popper>
        </div>
        <div class="title">
            <div class="text-wrapper">
                <h3>{{ option.name }}</h3>
                <p>{{ config.selection.name }} <template v-if="!config.selection.default">+{{ value }}</template></p>
            </div>
        </div>
        <div class="select-wrapper">
            <gn-button v-for="select in option.selects" :key="select.id" @click="editSelect([option.id,select.id])">{{ select.name }}</gn-button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Popper from "vue3-popper";
export default {
    components: {
        Popper,
    },
    props: ['option'],
    data() {
        return {
            active: false
        }
    },
    computed: {
        ...mapState(['config']),
        config(){
            return this.$store.state.config[this.option.slug];
        },
        value(){
            return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            }).format(this.config.value);
        },
    },
    methods: {
        ...mapActions([
            'editSelect'
        ]),
    }
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

:deep(.popper) {
    background: #2C273D;
    width: 100vw;
    max-width: 20rem;
    color: #fff;
    padding: .75rem;
    font-size: .825rem;
    border-radius: .5rem;
    header{
        font-weight: 700;
        margin-bottom: .35rem;
    }
    p{
        margin-bottom: unset;
    }
}

:deep(.popper #arrow::before) {
    background: #2C273D;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
    background: #2C273D;
}
@include media-breakpoint-down(md){
    :deep(.popper) {
        width: 85vw;
        max-width: unset;
    }
}

.selector{
    position: relative;
    .popper-wrapper{
        position: absolute;
        top: 0;
        right: 0;
        i{
            color: #8B859E;
        }
    }
    .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-items: flex-start;
        margin-bottom: 2rem;
        .text-wrapper{
            h3{
                font-size: 1rem;
                margin-bottom: unset;
            }
            p{
                margin: unset;
                color: $highlight;
                font-size: .8rem;
            }
        }
    }
    .select-wrapper{
        flex-wrap: wrap;
        display: flex;
        gap: .25rem;
    }
}
@include media-breakpoint-down(md){
    .selector{
        .title{
            margin-bottom: 1rem;
        }
    }
}
</style>
