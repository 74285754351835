import './bootstrap'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createApp } from 'vue'
import { createStore } from 'vuex'
import { createWebHistory, createRouter } from "vue-router";

/* Pages */
import index from './pages/index.vue';
import builder from './pages/builder.vue';
import membership from './pages/membership.vue';
import content from './pages/content.vue';
/* Components */
// Global
import globalheader from './components/global/header.vue';
import button from './components/builder/button.vue';
import diytoggle from './components/builder/diy-toggle.vue';
import dropdown from './components/dropdown.vue';
import build from './components/global/build.vue';
import globalfooter from './components/global/footer.vue';
import product from './components/global/product.vue';
import input from './components/global/input.vue';
// Index
import fact from './components/index/fact.vue';
// Builder
import category from './components/builder/category.vue';
import configdropdown from './components/builder/configDropdown.vue';
import configitem from './components/builder/configItem.vue';
//Membership
import plan from './components/membership/plan.vue';
import faq from './components/membership/faq.vue';
// Content
import individual_content from './components/content/individual-content.vue';

// Options
import options_style from './components/builder/options/style.vue';
import options_boolean from './components/builder/options/boolean.vue';
import options_thumbsticks from './components/builder/options/thumbsticks.vue';
import options_text from './components/builder/options/text.vue';
import options_rmb from './components/builder/options/remappable-buttons.vue';
import swatch from './components/builder/swatch.vue';

import { first } from 'lodash';

const store = createStore({
    state (){
        return{
            menu: [],
            content: [],
            building: false,
            preparingCart: false,
            products: [],
            selectedProduct: null,
            product: {},
            posts: [],
            //
            featured: [],
            modded: [],
            bsxb: [],
            bsps: [],
            //
            diy: false,
            view: 1,
            options: [],
            swatches: [],
            selects: [],
            customisations: {},
            cart: {},
            config: {},
            preview: [],
            //
            backgrounds: [],
            selectedBackground: 0
        }
    },
    mutations: {
        setMenu(state, delta){
            state.menu = delta;
        },
        setContent(state, delta){
            state.content = delta;
        },
        setBuilding(state, delta){
            state.building = delta;
        },
        setPreparingCart(state, delta){
            state.preparingCart = delta;
        },
        setProducts(state, products){
            state.products = products;
        },
        setSelectedProduct(state, productId){
            state.selectedProduct = productId;
        },
        setProduct(state, product){
            state.product = product;
        },
        setSwatches(state, swatches){
            state.swatches = swatches;
        },
        setSelects(state, selects){
            state.selects = selects;
        },
        setPosts(state, posts){
            state.posts = posts;
        },
        //
        setFeatured(state, featured){
            state.featured = featured;
        },
        setModded(state, modded){
            state.modded = modded;
        },
        setBSXB(state, bsxb){
            state.bsxb = bsxb;
        },
        setBSPS(state, bsps){
            state.bsps = bsps;
        },
        //
        setConfig(state, config){
            state.config = config;
        },
        setConfigVal(state, config){
            state.config[config.option][config.name] = config.value;
        },
        setConfigSelection(state, config){
            state.config[config.option].selection[config.name] = config.value;
        },
        // Unsorted
        toggleView(state){
            state.view = !state.view;
        },
        setView(state, value){
            state.view = value;
        },
        setDIY(state, diy){
            state.diy = diy;
        },
        setCategories(state, categories){
            state.categories = categories;
        },
        addProductToCart(state, item){
            state.cart.product = item
        },
        custom(state, value){
            state.customisations[value[0]] = value[1];
        },
        setPreview(state, value){
            state.preview = value;
        },
        setButtons(state, value){
            state.config[value[0]].selection.buttons = value[1];
        },
        setButtonPosition(state, value){
            state.config[value[0]].selection.buttons[value[1]].position = value[2];
        },
        setBackgrounds(state, backgrounds){
            state.backgrounds = backgrounds;
        },
        setSelectedBackground(state, delta){
            state.selectedBackground = delta;
        }
    },
    getters: {
        remappableButtons: state => {
            return state.config['remappable_buttons'] ? state.config['remappable_buttons'].selection.buttons : [];
        },
        visuals: state => {
            return Object.keys(state.config).reduce(function(res, v) {
                return res.concat(state.config[v].visuals);
            }, []).concat(state.preview).filter(function(v) {
                return v != null;
            })
        },
        categories: state => {
            return state.product ? state.product.categories : [];
        },
        options: (state, getters) => {
            return Object.keys(getters.categories).reduce(function(res, i) {
                return res.concat(getters.categories[i].options);
            }, [])
        },
        cart: state => {
            return Object.fromEntries(Object.entries(state.config).filter(([key, v]) => v.selection.default != true));
        },

        cartTotal: (state, getters) => {
            // Init Price
            let value = 0;
            // If not a DIY Build, include the price of the product
            if(!state.diy){
                value += parseFloat(state.product.value);
            }
            value += Object.keys(getters.cart).reduce(function(res, i) {
                return res.concat(parseFloat(getters.cart[i].value));
            }, []).reduce((a, b) => a + b, 0)
            // value += state.config.reduce(function(prev, cur) {
            //     return prev + cur.value;
            // }, 0);
            return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            }).format(value);
        }
    },
    actions: {
        getMenu({ commit }) {
            axios.get('/api/menu').then(menu=>{
                if(menu.status == 200){
                    commit('setMenu', menu.data);
                }
            });
        },
        getContent({ commit }) {
            axios.get('/api/content').then(content=>{
                if(content.status == 200){
                    commit('setContent', content.data);
                }
            });
        },
        getAllProducts({ commit }) {
            axios.get('/api/products').then(products=>{
                if(products.status == 200){
                    commit('setProducts', products.data);
                }
            });
        },
        getProduct({state, commit}) {
            if(state.selectedProduct){
                // Get Product
                axios.get('/api/product',{
                    params: {
                        product: state.selectedProduct
                    }
                }).then(product=>{
                    if(product.status == 200){
                        commit('setProduct', product.data);
                        store.dispatch('generateConfig');
                    }
                });
            }
        },
        getSwatches({ commit }) {
            axios.get('/api/swatches').then(res=>{
                if(res.status == 200){
                    commit('setSwatches', res.data);
                }
            });
        },
        getSelects({ commit }) {
            axios.get('/api/selects').then(res=>{
                if(res.status == 200){
                    commit('setSelects', res.data);
                }
            });
        },
        getPosts({ commit }) {
            axios.get('/api/posts').then(res=>{
                if(res.status == 200){
                    commit('setPosts', res.data);
                }
            });
        },
        //
        getFeatured({ commit }) {
            axios.get('/api/featured').then(res=>{
                if(res.status == 200){
                    commit('setFeatured', res.data);
                }
            });
        },
        getModded({ commit }) {
            axios.get('/api/modded').then(res=>{
                if(res.status == 200){
                    commit('setModded', res.data);
                }
            });
        },
        getBSXB({ commit }) {
            axios.get('/api/bsxb').then(res=>{
                if(res.status == 200){
                    commit('setBSXB', res.data);
                }
            });
        },
        getBSPS({ commit }) {
            axios.get('/api/bsps').then(res=>{
                if(res.status == 200){
                    commit('setBSPS', res.data);
                }
            });
        },
        getBackgrounds({ commit }) {
            axios.get('/api/backgrounds').then(res => {
                if(res.status == 200) {
                    commit('setBackgrounds', res.data);
                }
            });
        },
        //
        generateConfig({commit}){
            var productOptions = {};
            $.each(store.getters.options, function(key, option){
                var value = 0.00;
                var selection = {};
                var visuals = null;
                // Style
                if(option.type_id == 1 && option.default_swatch){
                    var swatch = option.default_swatch;
                    var value = swatch.value;
                    var visuals = swatch.visuals;
                    var selection = {
                        id: swatch.id,
                        name: swatch.name,
                        default: swatch.default
                    }
                }
                // Select
                if(option.type_id == 2 && option.default_select){
                    var select = option.default_select;
                    var value = select.value;
                    var visuals = select.visuals;
                    var selection = {
                        id: select.id,
                        name: select.name,
                        default: select.default
                    }
                }
                // Thumbsticks
                if(option.type_id == 3 && option.default_swatch){
                    var swatch = option.default_swatch;
                    var value = swatch.value;
                    var visuals = swatch.visuals;
                    var selection = {
                        id: swatch.id,
                        name: swatch.name,
                        default: swatch.default
                    }
                }
                // Remappable Buttons
                if(option.type_id == 4){
                    var value = 0.00;
                    var visuals = [];
                    var selection = {
                        buttons: [],
                        default: true
                    }
                }
                productOptions[option.slug] = {
                    name: option.name,
                    value: value,
                    selection: selection,
                    visuals: visuals,
                }
            });
            commit('setConfig', productOptions);
        },
        //
        setPreview({ commit }, value) {
            let swatch_id = value[1];
            let swatch = store.state.swatches.filter(function(obj){ return obj.id == swatch_id; })[0].visuals;
            let firstSwatch = swatch[0];
            let preview = [];

            $.each(swatch, function(key, visual){
                preview.push({
                    image: visual.image,
                    sorting: visual.sorting+1,
                    view: visual.view
                });
            });
            // let cfp = Object.keys(store.state.config).reduce(function(res, v) {
            //     return res.concat(parseFloat(store.state.config[v].value));
            // }, [])
            // let cfp = store.state.config.filter(function(obj){ return obj.selection.default; });
            // let cfp = Object.keys(store.state.config).reduce( (res, key) => (res[key] = store.state.config[key], res), {} );

            // .filter(function(v) {
            //     return v.selection.default == false;
            // })

            // let cfp = Object.keys(store.state.config).reduce((prev, cur) => prev + cur.value, 0);
            // console.log(cfp);

            // console.log('preview');


            // console.warn(swatch);
            // console.warn(store.getters.visuals);
            // if(value.length){
            //     let visual = value[0]
            //     let preview = {
            //         image: visual.image,
            //         sorting: visual.sorting+1,
            //         view: visual.view
            //     }
            //     state.preview = preview;
            //     if(visual.view == 1){
            //         state.view = true;
            //     }else{
            //         state.view = false;
            //     }

            // }else{
            //     state.preview = {};
            // }
            commit('setView', firstSwatch.view);
            commit('setPreview', preview);
        },
        resetPreview({ commit }) {
            commit('setPreview', {});
        },
        // getProductCategories({ commit }) {
        //     axios.get('/api/categories',{
        //         params: {
        //             product: store.state.selectedProduct
        //         }
        //     }).then(res=>{
        //         if(res.status == 200){
        //             commit('setCategories', res.data);
        //             store.dispatch('generateConfig');
        //         }
        //     });
        // },
        addProductToCart({commit, state, getters}) {
            commit('setBuilding', true)
            axios.post('/api/cart', {
                productId: state.product.id,
                productName: state.product.name_short,
                price: getters.cartTotal,
                config: state.config
            }).then(r => {
                // console.log(JSON.stringify(r.data));
                if(r.data.success) {
                    commit('setPreparingCart', true);
                    setInterval(() => {
                        window.location.replace(r.data.link)
                    }, 5000)
                }
            }).catch(error => {
                console.error(error);
            });
        },
        addCustomisation({ commit }, value) {
            commit('custom', value)
        },
        editConfig({ commit }, value) {
            var option = value[0];
            var v = value[1];

            var swatch = store.state.swatches.filter(function(obj){ return obj.id == v; })[0];
            var opt = store.getters.options.filter(function(obj){ return obj.id == option; })[0];


            // console.log(swatch);
            // console.log(option);
            // console.log(opt);

            // productOptions[option.slug] = {
            //     selection: selection = {
            //         id: swatch.id,
            //         name: swatch.name
            //     },
            //     visuals: visuals,
            // }
            // if(v == 1){
            //     var configItem = {
            //         name: 'Front Shell',
            //         value: 0.00,
            //         selection: {
            //             id: '1',
            //             name: 'Black (Default)'
            //         },
            //         product_img: 'https://cdn.gamenetics.com/options/xbox_series/front_shell/black.png'
            //     }
            // }
            var selection = {
                id: swatch.id,
                name: swatch.name,
                default: swatch.default
            }
            commit('setConfigVal', {
                option: opt.slug,
                name: 'value',
                value: swatch.value
            });
            commit('setConfigVal', {
                option: opt.slug,
                name: 'selection',
                value: selection
            });
            commit('setConfigVal', {
                option: opt.slug,
                name: 'visuals',
                value: swatch.visuals
            });
        },
        editSelect({ commit }, value){
            var option = value[0];
            var v = value[1];

            var select = store.state.selects.filter(function(obj){ return obj.id == v; })[0];
            var opt = store.getters.options.filter(function(obj){ return obj.id == option; })[0];

            var selection = {
                id: select.id,
                name: select.name,
                default: select.default
            }

            commit('setConfigVal', {
                option: opt.slug,
                name: 'value',
                value: select.value
            });
            commit('setConfigVal', {
                option: opt.slug,
                name: 'selection',
                value: selection
            });
        },
        editText({ commit }, value){
            var option = value[0];
            var v = value[1];

            var opt = store.getters.options.filter(function(obj){ return obj.id == option; })[0];

            var selection = {
                text: v,
                default: v ? false : true
            }

            commit('setConfigVal', {
                option: opt.slug,
                name: 'value',
                value: v ? 24.99 : 0.00
            });
            commit('setConfigVal', {
                option: opt.slug,
                name: 'selection',
                value: selection
            });
        }
    }
})

function checkProduct(to) {
    if(to.hash && to.hash == '#xbox'){
        store.commit('setSelectedProduct',1);
    }else if(to.hash && to.hash == '#playstation'){
        store.commit('setSelectedProduct',2);
    }else{
        store.commit('setSelectedProduct',2);
    }
}

/* Routes */
const routes = [
    { path: '/', name: 'index', component: index },
    { path: '/builder', name: 'builder', component: builder, beforeEnter: checkProduct },
    { path: '/membership', name: 'membership', component: membership },
    { path: '/content', name: 'content', component: content },
    { path: '/:catchAll(.*)', redirect: { name: 'index' }}
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});
const app = createApp({
}).use(router, VueAxios, axios);
app.use(store);

store.dispatch('getMenu');
store.dispatch('getContent');
store.dispatch('getAllProducts');
store.dispatch('getProduct');
store.dispatch('getSwatches');
store.dispatch('getSelects');
store.dispatch('getPosts');
//
// store.dispatch('getFeatured');
store.dispatch('getModded');
store.dispatch('getBSXB');
store.dispatch('getBSPS');
store.dispatch('getBackgrounds');
//
store.watch(
    (state) => state.selectedProduct, (oldValue, newValue) => {
        store.commit('setDIY', false);
        store.dispatch('getProduct');
    },
);
// store.watch(
    // (getters) => store.getters.remappableButtons, (buttons) => {
    //     let visuals = [];
    //     $.each(buttons, function(key, button){
    //         let swatch = store.state.swatches.filter(function(swatch){ return swatch.id == button.swatch; })[0];
    //         visuals.push(swatch.visuals[0]);
    //     });
    //     console.log('Button Changed');
    //     store.commit('setConfigVal', {
    //         option: 'remappable_buttons',
    //         name: 'visuals',
    //         value: visuals
    //     });
    // }, { deep: true }
// );

// Global
app.component('global-header', globalheader);
app.component('global-footer', globalfooter);
app.component('gn-button', button);
app.component('diytoggle', diytoggle);
app.component('dropdown', dropdown);
app.component('build', build);
app.component('product', product);
app.component('Input', input);

// Index
app.component('fact',fact);


// Builder
app.component('category', category);
app.component('configDropdown', configdropdown);
app.component('configItem', configitem);

// Options
app.component('option-style', options_style);
app.component('option-boolean', options_boolean);
app.component('option-thumbsticks', options_thumbsticks);
app.component('option-rmb', options_rmb);
app.component('option-text', options_text);

// Helpers
app.component('swatch', swatch);

// Membership
app.component('plan', plan);
app.component('faq', faq);

// Content
app.component('individual-content', individual_content);

//
app.mount('#app');
