<template>
    <section id="membership">
        <div class="container-fluid">
            <div class="row">
                <plan/>
                <plan/>
                <plan/>
            </div>
        </div>
    </section>
    <build/>
    <section id="faq">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="heading">
                        <p class="highlight">Need More Info</p>
                        <h2>Frequently Asked Questions</h2>
                    </div>
                    <faq/>
                    <faq/>
                    <faq/>
                    <faq/>
                    <faq/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
section#membership{
    background: url('https://cdn.gamenetics.com/images/elements/hero-pattern.svg') no-repeat;
    background-position: top center;
    background-size: 100% cover;
    padding-top: 7.5rem;
}
section#faq{
    .heading{
        margin-bottom: 3rem;
    }
}
</style>