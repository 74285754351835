<template>
    <div class="toggle" ref="toggle">
        <div class="highlight" ref="highlight">
            <div class="leading"></div>
            <div class="middle" ref="highlightmiddle"></div>
            <div class="trailing"></div>
        </div>
        <div class="leading"></div>

        <div :class="[!$store.state.diy ? 'active' : '', 'toggle-item']" @click="selectItem('pre'), setDiy(false)" ref="prebuilt">PreBuilt</div>
        <div :class="[$store.state.diy ? 'active' : '', 'toggle-item']" v-if="$store.state.product.diy" @click="selectItem('diy'), setDiy(true)" ref="diy">DIY Kit</div>

        <div class="trailing"></div>
        <div class="backtrack">
            <div class="leading"></div>
            <div class="middle"></div>
            <div class="trailing"></div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data(){
        return{
            options: ['Prebuilt','DIY Kit']
        }
    },
    mounted() {
        setTimeout(() => this.selectItem('pre'), 100);

    },
    methods: {
        addClass: function(target){
            target.classList.add('active');
        },
        selectItem: function(ref){
            // Remove Active Items
            let items = this.$el.querySelectorAll('.toggle-item');
            items.forEach(el => el.classList.remove('active'));

            let parentRect = this.$refs.toggle.getBoundingClientRect();
            let highlight = this.$refs.highlight;

            if(ref == 'pre'){
                let item = this.$refs.prebuilt;
                item.classList.add('active');
                let targetRect = item.getBoundingClientRect();
                highlight.style.left = `${targetRect.left - parentRect.left - 8}px`;

                // console.log(`${targetRect.left - parentRect.left}px`);
                // console.log(targetRect);

                this.$refs.highlightmiddle.style.width = `${targetRect.width}px`;
            }

            if(ref == 'diy'){
                let item = this.$refs.diy;
                item.classList.add('active');

                let targetRect = item.getBoundingClientRect();
                highlight.style.left = `${targetRect.left - parentRect.left - 8}px`;
                // console.log(`${targetRect.left - parentRect.left - 8}px`);
                // console.log(targetRect);

                this.$refs.highlightmiddle.style.width = `${targetRect.width}px`;
                // highlight.style.left = `${targetRect.left - parentRect.left - 8}px`;

            }

            // const target = event.target;
            // const parent = this.$refs.toggle;
            // const targetRect = target.getBoundingClientRect();
            // const parentRect = parent.getBoundingClientRect();

            // // this.addClass(target);
            // let highlight = this.$refs.highlight;
            // highlight.style.left = `${targetRect.left - parentRect.left - 8}px`;
            // this.setHighlightWidth(target);
        },
        setHighlightWidth: function(target = null) {
            const itemTarget = target;
            // console.log(target);
            const rect = itemTarget.getBoundingClientRect();
            console.log(rect);

            this.addClass(itemTarget);
            let highlight = this.$refs.highlightmiddle;
            highlight.style.width = `${rect.width}px`;
        },
        setDiy: function(diy){
            this.$store.commit('setDIY', diy);
        }
    },
    computed: mapState(['diy']),
    watch: {
        diy(val, oldValue) {
            if(val){
                this.selectItem('diy');
            }else{
                this.selectItem('pre');
            }
        },
    },
}
</script>

<style lang="scss" scoped>
    @import './resources/sass/_variables.scss';
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';
    .toggle{
        display: flex;
        cursor: pointer;
        position: relative;
        .leading{
            width: .5rem;
            clip-path: polygon(100% 0, 0 50%, 100% 100%);
        }
        .trailing{
            width: .5rem;
            clip-path: polygon(0 0, 100% 50%, 0 100%);
        }
        .toggle-item{
            transition: color 0.2s;
            padding: .45rem 1rem;
            color: darken($accent,5%);
            font-size: .75rem;
            letter-spacing: .06rem;
            text-transform: uppercase;
            font-weight: 600;
            z-index: 1;
            &.active{
                color: #fff;
            }
        }
        .highlight{
            display: flex;
            transition: width 0.2s, left 0.2s;
            position: absolute;
            z-index: 1;
            height: 100%;
            .leading, .middle, .trailing{
                background: $accent;
                height: 100%;
            }
            .leading{
                position: relative;
                left: .4px;
            }
            .middle{
                transition: width 0.2s;
                flex-grow: 1;
                min-width: 2rem;
            }
        }
        .backtrack{
            .leading, .middle, .trailing{
                position: absolute;
                height: 100%;
                top: 0;
                background: darken($accent,35%);
            }
            .leading{
                left: 0;
            }
            .middle{
                width: calc(100% - .95rem);
                left: .5rem;
            }
            .trailing{
                right: -.7px;
            }
        }
    }
</style>
