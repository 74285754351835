<template>
    <section :style="`background: linear-gradient(180deg, rgba(0, 0, 0, 0) 78.37%, #000000 100%), url('https://res.cloudinary.com/papercrowns/image/upload/c_fill,h_640,q_auto,w_1440/v1/${content['index-hero-background']}') no-repeat;background-position: top center;background-size: cover;`" id="hero" v-if="content['index-hero-background']">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-xl-8 col-md-10 wrapper">
                    <img class="logo" src="https://cdn.gamenetics.com/images/logos/wordmark.svg" alt="Gamenetics">
                    <h1>{{ content['hero-text'] }}</h1>
                    <img class="controller" src="https://cdn.gamenetics.com/images/elements/hero.png" alt="Gamenetics">
                    <div class="d-flex" style="gap: .5rem; margin-top: 2rem;" v-if="content['hero-buttons']">
                        <gn-button v-for="item, i in JSON.parse(content['hero-buttons'])" :key="i" :to="item.to" :href="item.href">{{ item.name }}</gn-button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="featured-products" class="mb-5" v-if="bsps.length">
        <div class="container-fluid">
            <div class="heading">
                <h3>PS5 Best Sellers</h3>
                <p>A selection of our most popular builds.</p>
            </div>
            <div class="divider"></div>
            <div class="row">
                <div v-for="product in bsps" :key="product.link" class="col-md-3 product">
                    <product :product="product"/>
                </div>
            </div>
        </div>
    </section>
    <section id="featured-products" v-if="bsxb.length">
        <div class="container-fluid">
            <div class="heading">
                <h3>Xbox Best Sellers</h3>
                <p>A selection of our most popular builds.</p>
            </div>
            <div class="divider"></div>
            <div class="row">
                <div v-for="product in bsxb" :key="product.link" class="col-md-3 product">
                    <product :product="product"/>
                </div>
            </div>
        </div>
    </section>
    <!-- <build/> -->
    <section id="featured-products" v-if="modded.length">
        <div class="container-fluid">
            <div class="heading">
                <h3>Modded Controllers</h3>
                <p>A selection of our Modded & Pro Controller builds.</p>
            </div>
            <div class="divider"></div>
            <div class="row">
                <div v-for="product in modded" :key="product.link" class="col-md-3 product">
                    <product :product="product"/>
                </div>
            </div>
        </div>
    </section>
    <fact>
        <template v-slot:tag>
            Infinite Possibilities
        </template>
        <template v-slot:title>
            We are the most customizable controller company in the world. Period.
        </template>
        <template v-slot:desc>
            We believe that anything is possible, and we’ve worked that into our product lineups. One thing that is very important to Gamenetics is providing infinite possibilities. Our controller builder is one big step in that direction. We are focused on the future and creating more and more options, designs and colors to help you make the personalization process easy. We are also focused on competitive controller builds.</template>
        <template v-slot:image>
            <img src="https://cdn.gamenetics.com/images/elements/customizable.jpg" alt="Gamenetics Customizable">
        </template>
    </fact>
    <fact>
        <template v-slot:tag>
            The Details matter
        </template>
        <template v-slot:title>
            Every product is handcrafted and packaged by hand, with absolute care.
        </template>
        <template v-slot:desc>
            We care about the details. Not only is each controller handcrafted, but we make sure that your unboxing experience is as special as it can be. We’ve worked hard to create an experience where we can feel more connected with each of you individually. We’ve done this by thinking about each step of the process and tried to personalize the experience as much as possible.
        </template>
        <template v-slot:image>
            <img src="https://cdn.gamenetics.com/images/elements/packaging.jpg" alt="Gamenetics Customizable">
        </template>
    </fact>
     <fact>
        <template v-slot:tag>
            Personalized Experiences
        </template>
        <template v-slot:title>
            The team at Gamenetics believes in you and your passion for gaming
        </template>
        <template v-slot:desc>
            Since 2015, we’ve assembled over 70,000 controllers and become experts on how to build and customize them. We know that functionality and reliability are 2 key components that you value. We not only offer a standard 60 day warranty on any of our products but we believe adding a personalized experience and care with what we do is paramount.
        </template>
        <template v-slot:image>
            <img src="https://cdn.gamenetics.com/images/elements/meet-the-team.jpg" alt="Meet The Team">
        </template>
    </fact>

</template>

<script>
import { mapState } from 'vuex'
export default{
    computed: {
        ...mapState(['content', 'featured', 'modded', 'bsps', 'bsxb'])
    }
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
section#hero{
    padding-top: 8rem;
    overflow: hidden;
    .wrapper{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 30rem;
        text-align: center;
        img.controller{
            position: absolute;
            bottom: 0;
            top: 0;
            height: 100%;
            width: 100%;
            object-fit: contain;
            animation: grow 45s;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
        }
        img.logo{
            height: 1.25rem;
            margin-bottom: 2rem;
            position: relative;
            z-index: 1;
        }
        h1{
            position: relative;
            z-index: 1;
            span{
                font-weight: 100;
            }
        }
    }
}
section#featured-products{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    .heading {
        display: flex;
        flex-direction: column;
        margin-bottom: .5rem;
        h3, p {
            margin-bottom: 0;
        }
        p {
            color: grey;
        }
    }
    .divider{
        width: 100%;
        height: 1px;
        background: #2F2F2F;
        margin-bottom: 1.5rem;
    }
}

section#fact{
    &:last-of-type{
        margin-bottom: unset;
    }
    .content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 48rem;
        h2{
            margin-bottom: 4rem;
        }
        p{
            color: #8E8E8E;
            margin-bottom: 2rem;
        }
        p.highlight{
            color: $accent;
            margin-bottom: 1rem;
        }
    }
    img{
        width: 100%;
    }
}

@keyframes grow{
    0%{
        transform: scale(1);
    }
    100%{
        transform: scale(1.1);
    }
}
@include media-breakpoint-only(xxl){
    section#hero{
        .wrapper{
            h1{
                max-width: 50rem;
                font-size: 4rem;
            }
        }
    }
    section#packaging{
        margin-bottom: 8rem;
    }
}
@media screen and (max-width: 1400px) {
    section#hero{
        .wrapper{
            h1{
                max-width: 37.5rem;
            }
        }
    }
}
@include media-breakpoint-down(xl){
    section#customizable{
        .content-wrapper{
            img{
                bottom: -5rem;
                left: -15%;
                width: 65%;
            }
        }
    }
    section#packaging{
        margin-top: 14rem;
        .content-wrapper{
            img{
                top: -4rem;
                right: -10%;
                width: 65%;
            }
        }
    }
    section#insurance{
        .content-wrapper{
            img{
                bottom: -5rem;
                left: -15%;
                width: 65%;
            }
        }
    }
}
@include media-breakpoint-down(lg){
     section#hero{
        background-size: 160%;
        background-position: top center;
        .wrapper{
            justify-content: flex-start;
            height: unset;
            margin-bottom: 3rem;
            h1{
                margin-bottom: 2rem;
            }
            img.controller{
                position: static;
                height: unset;
            }
        }
    }
    section#customizable{
        .content-wrapper{
            flex-direction: column;
            img{
                position: relative;
                bottom: 0;
                left: 0;
                width: 80%;
            }
        }
    }
    section#packaging{
        margin-top: 2rem;
        .content-wrapper{
            flex-direction: column;
            img{
                position: relative;
                top: 3rem;
                left: -10%;
                width: 80%;
            }
        }
    }
    section#insurance{
        .content-wrapper{
            flex-direction: column;
            img{
                position: relative;
                // bottom: 0;
                left: -10%;
                width: 80%;
            }
        }
    }
}
@include media-breakpoint-down(md){
    section#hero {
        padding-top: 10rem;
    }
    section#customizable{
        .content-wrapper{
            img{
                width: 100%;
            }
        }
    }
    section#featured-products{
        .product{
            margin-bottom: 2rem;
            &:last-child{
                margin-bottom: unset;
            }
        }
    }
    section#packaging{
        .content-wrapper{
            img{
                left: 0;
                width: 100%;
            }
        }
    }
    section#insurance{
        .content-wrapper{
            .content{
                margin-bottom: -4rem;
            }
            img{
                left: 0;
                width: 100%;
            }
        }
    }
}
@include media-breakpoint-down(sm){
    section#featured-products{
        .heading{
            flex-direction: column;
            justify-content: center;
            text-align: center;
            h3{
                margin-bottom: 1rem;
            }
        }
    }
}
</style>
