<template>
   <a class="product" :href="product.link">
      <div class="product-image">
         <img :src="product.image" alt="">
      </div>
      <div class="product-details">
         <h3>{{ product.title }}</h3>
         <div class="price">
             <div class="compare"  v-if="product.price">
                <!-- <h3 class="amount sale">$149.99</h3> -->
                <h3 class="amount">${{ product.price }}</h3>
             </div>
            <gn-button class="large">
                Shop Now
            </gn-button>
         </div>
      </div>
   </a>
</template>

<script>
export default {
    props: ['product']
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
a.product{
    transition: background 300ms;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
   .product-image{
        position: relative;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
        border: 1px solid #222;
        backdrop-filter: blur(6px);
        border-radius: .6rem;
        transition: all 300ms;
        margin-bottom: 2rem;
        overflow: hidden;
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(135deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 100%);
            border-radius: .6rem;
            opacity: 0;
            transition: opacity .4s ease;
        }
       img{
           position: relative;
           z-index: 1;
           width: 100%;
       }
   }
   .product-details{
       display: flex;
       flex-direction: column;
       justify-content: flex-start;
       align-items: flex-start;
       h3{
            font-size: 1.5rem;
            line-height: 2rem;
            color: #fff;
            text-transform: uppercase;
            font-family: urw-din, sans-serif;
            font-weight: 700;
            margin-bottom: 1rem;
           &.amount{
               font-size: 1.25rem;
               font-weight: 200;
               margin-bottom: unset;
               &.sale{
                color: #333;
                text-decoration: line-through;
                margin-right: 1rem;
               }
           }
       }
       .compare{
           display: flex;
           align-items: center;
       }
       .price{
           width: 100%;
           display: flex;
           justify-content: space-between;
           button.collection-atc{
                position: relative;
                right: .5rem;
                padding: .45rem 1rem;
                color: white;
                font-size: .7rem;
                letter-spacing: .06rem;
                text-transform: uppercase;
                font-weight: 600;
                border-radius: unset;
                border: unset;
                background: $accent;
                transition: all .3s ease;
                &::before{
                    content: '';
                    position: absolute;
                    width: .5rem;
                    height: 100%;
                    top: 0;
                    left: calc(-.5rem + .5px);
                    background: $accent;
                    clip-path: polygon(100% 0, 0 50%, 100% 100%);
                }
                &::after{
                    content: '';
                    position: absolute;
                    width: .5rem;
                    height: 100%;
                    top: 0;
                    right: calc(-.5rem + .2px);
                    background: $accent;
                    clip-path: polygon(0 0, 100% 50%, 0 100%);
                }
                &:hover{
                    filter: brightness(1.2);
                }
           }
       }
   }
   &:hover{
        color: #fff;
        .product-image{
            border: 1px solid #282828;
            &::before{
                opacity: .2;
            }
        }
   }
   &:last-child{
       margin-bottom: unset;
   }
   &.full{
       .product-image{
           margin-bottom: unset;
           &::before{
               content: unset;
           }
       }
   }
}
@include media-breakpoint-down(lg){
    a.product{
        text-align: center;
        .product-details{
            justify-content: center;
            align-items: center;
            h3{
                margin-bottom: unset;
                font-size: 1rem;
            }
            .compare{
                margin-bottom: 1rem;
                justify-content: center;
                h3{
                    font-size: 1rem;
                }
            }
            .price{
                flex-direction: column;
                justify-content: center;
                align-items: unset;
                .button{
                    right: unset;
                    &.product-button{
                        .middle{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
@include media-breakpoint-down(md){

}
</style>
