<template>
    <section>
        <div class="container-fluid">
            <div class="wrapper">
                <div class="row">
                    <div class="col-lg-6 text">
                        <h1>Build your own custom controller</h1>
                        <div class="buttons">
                            <gn-button to="builder" hash="#playstation">
                                Customize PS5
                            </gn-button>
                            <gn-button to="builder" hash="#xbox">
                                Customize Xbox
                            </gn-button>
                            <a class="button secondary" href="https://shop.gamenetics.com/collections">Browse All</a>
                        </div>
                    </div>
                    <div class="col-lg-6 image">
                        <img class="gif" src="https://cdn.gamenetics.com/images/elements/hero.gif" alt="">
                        <div class="hexagon">
                            <img src="https://cdn.gamenetics.com/images/elements/build-hexagon.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
section{
    position: relative;
    margin: 8rem 0 4rem;
    color: #fff;
    .wrapper{
        padding-left: 4rem;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%), url('https://cdn.gamenetics.com/images/elements/hero-pattern.svg') 60% no-repeat;
        background-position: top left;
        border: 1px solid #222;
        border-radius: .6rem;
        overflow: hidden;
        .text{
            display: flex;
            flex-direction: column;
            justify-content: center;
            h1{
                margin-bottom: 4rem;
                font-size: clamp(2.4rem, 3.8vw, 4rem);
            }
            .buttons{
                display: flex;
                align-items: center;
                a{
                    margin-left: 2rem;
                }
            }
        }
        .image{
            position: relative;
            .hexagon{
                position: absolute;
                z-index: 0;
                right: 0;
                top: 0;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                img{
                    height: 100%;
                }
                &:after{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 95%;
                    width: 1000%;
                    height: 100%;
                    background: $accent;
                }
            }
            img.gif{
                width: 100%;
                position: relative;
                z-index: 1;
                animation: hover 8s infinite;
            }
        }
    }
}
@keyframes hover{
   0%{
       transform: translateY(-.5rem);
   }
   50%{
       transform: translateY(.5rem);
   }
   100%{
       transform: translateY(-.5rem);
   }
}
@include media-breakpoint-down(lg){
    section{
        margin: 4rem 0;
        background-size: 130%;
        .wrapper{
            background-position: bottom center;
            background-size: 200%;
            padding-top: 3rem;
            padding-left: 3rem;
            .text{
                h1{
                    font-size: 3.2rem;
                }
                .buttons{
                    a{
                        margin-left: 1rem;
                    }
                }
            }
        }
    }
}
@include media-breakpoint-down(md){
    section{
        .wrapper{
            padding-top: 2rem;
            padding-left: 1rem;
            .text {
                .buttons{
                    flex-direction: column;
                    a{
                        margin-left: unset;
                        margin-bottom: .5rem;
                    }
                }
                margin-bottom: 4rem;
                h1{
                    margin-bottom: 2rem;
                }
            }
        }
    }
}
@include media-breakpoint-down(sm){
    section{
        .wrapper{
            padding-left: unset;
            .text{
                align-items: center;
                text-align: center;
                margin-bottom: 3rem;
                h1{
                    font-size: 1.75rem;
                    margin-bottom: 1.5rem;
                }
            }
        }
    }
}
</style>
