
<template>
    <header>
        <a id="headline" :href="content['headline-bar'][1]" v-if="content['headline-bar'] && content['headline-bar'][0] && content['headline-bar'][1]">
            <p>{{ content['headline-bar'][0] }}</p>
        </a>
        <div class="container-fluid">
            <div class="wrapper">
                <div class="leading">
                    <router-link :to="{name: 'index'}" class="brand" @click="expanded = null">
                        <img src="https://cdn.gamenetics.com/images/logos/gamenetics.svg" alt="Gamenetics">
                        <img src="https://cdn.gamenetics.com/images/logos/icon.svg" alt="Gamenetics">
                    </router-link>
                </div>
                <nav class="main">
                    <ul>
                        <template v-for="item, i in menu">
                            <li class="nav-item" @click="expanded = expanded == i ? null : i " :key="item.slug" v-if="item.submenu?.length">
                                <div class="nav-item_title">
                                    <p role="button">{{ item.name }}</p>
                                    <i class="fas fa-chevron-down"></i>
                                </div>
                                <!-- Submenu -->
                                <ul class="submenu" v-if="item.submenu && expanded == i">
                                    <li v-for="subitem in item.submenu" :key="subitem.slug">
                                        <router-link class="nav-item" :to="{ name: subitem.to }" v-if="subitem.to">{{ subitem.name }}</router-link>
                                        <a class="nav-item" :href="subitem.href" v-else>{{ subitem.name }}</a>
                                    </li>
                                </ul>
                            </li>
                             <li class="nav-item" :key="item.slug" v-else>
                                <router-link class="nav-item" :to="{ name: item.to }" v-if="item.to">{{ item.name }}</router-link>
                                <a class="nav-item_submenu" :href="item.href" v-else>{{ item.name }}</a>
                            </li>
                        </template>
                    </ul>
                    <ul v-if="$route.name == 'builder'">
                        <div class="options-wrapper">
                            <dropdown @change="changeProduct($event)" :icon="$store.state.product.icon">
                                <option :value="product.id" v-for="product in $store.state.products" :key="product.id" :selected="$store.state.selectedProduct == product.id">{{ product.name_short }}</option>
                            </dropdown>
                            <!-- <div class="options">
                                <diytoggle/>
                            </div> -->
                        </div>
                    </ul>
                </nav>
                <nav class="mobile" v-bind:class="{active:isActive}">
                    <ul>
                        <template v-for="item in menu">
                            <li class="nav-item" @click="expanded = !expanded" :key="item.slug" v-if="item.submenu?.length">
                                <div class="nav-item_title">
                                    <p role="button">{{ item.name }}</p>
                                    <i class="fas fa-chevron-down"></i>
                                </div>
                                <!-- Submenu -->
                                <ul class="submenu" v-if="item.submenu && expanded">
                                    <li v-for="subitem in item.submenu" :key="subitem.slug">
                                        <router-link class="nav-item" :to="{ name: subitem.to }" v-if="subitem.to">{{ subitem.name }}</router-link>
                                        <a class="nav-item" :href="subitem.href" v-else>{{ subitem.name }}</a>
                                    </li>
                                </ul>
                            </li>
                             <li class="nav-item" :key="item.slug" v-else>
                                <router-link class="nav-item" :to="{ name: item.to }" v-if="item.to">{{ item.name }}</router-link>
                                <a class="nav-item_submenu" :href="item.href" v-else>{{ item.name }}</a>
                            </li>
                        </template>
                    </ul>
                </nav>
                <div class="trailing">
                    <div class="config-wrapper" v-if="$route.name == 'builder'">
                        <!-- <config-dropdown/>
                        <div class="config">
                            <p>View Config</p>
                            <i class="fas fa-caret-down"></i>
                        </div> -->
                        <div class="checkout" @click="addProductToCart">
                            <gn-button>Buy Now - {{ $store.getters.cartTotal }}</gn-button>
                        </div>
                    </div>
                    <a class="cart" href="https://shop.gamenetics.com/cart" v-if="$route.name != 'builder'"><i class="bi bi-bag"></i></a>
                    <div class="menu" v-bind:class="{active:isActive}" v-on:click="toggleMobile">
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
    data() {
        return {
            isActive: false,
            expanded: null
        }
    },
    computed: {
        ...mapState([
            'menu',
            'content'
        ]),
        routeName() {
            return this.$route.name;
        }
    },
    methods: {
        ...mapActions([
            'addProductToCart'
        ]),
        changeProduct(event){
            console.log(event.target.value);
            this.$store.commit('setSelectedProduct',event.target.value);
        },
        toggleMobile: function() {
            this.isActive = !this.isActive;
        },
        closeMobile: function() {
            setTimeout(() => this.isActive = false, 100);
        },
    },
    watch: {
        routeName() {
            this.expanded = null
        }
    }
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

header{
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    width: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    background: #000;
    backdrop-filter: blur(8px);
    border-bottom: solid 1px #222;
    #headline{
        background: $accent;
        color: #fff;
        top: 0;
        left: 0;
        min-width: 100vw;
        margin: 0 calc(50% - 50vw);
        text-align: center;
        padding: .25rem 0;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        p{
            font-size: .8rem;
            line-height: 1.1;
            font-weight: 700;
            margin-bottom: unset;
        }
    }
    .wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: $header-height;
        img{
            height: 2rem;
        }
        nav{
            display: flex;
            gap: 1rem;
            ul{
                display: flex;
                align-items: center;
                list-style-type: none;
                margin: unset;
                padding: unset;
                li{
                    margin-right: 1.5rem;
                    &:last-child{
                        margin-right: unset;
                    }
                    a,p{
                        color: #fff;
                        text-decoration: none;
                        text-transform: uppercase;
                        font-size: .85rem;
                        font-weight: 700;
                        margin-bottom: unset;
                        &:hover{
                            color: #fff;
                        }
                    }
                    &.nav-item {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        .nav-item_title {
                            display: flex;
                            align-items: center;
                            gap: 1rem;
                        }
                        ul.submenu {
                            position: absolute;
                            top: calc(100% + 1.4rem);
                            left: 0;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            background: rgba(0,0,0,.8);
                            padding: 1rem;
                            border: solid 1px #262626;
                            border-radius: .5rem;
                            backdrop-filter: blur(8px);
                            li{
                                white-space: nowrap;
                                padding: unset;
                                a,div,p {
                                    padding: .625rem 0;
                                }
                            }
                            @include media-breakpoint-down(lg) {
                                position: static;
                                background: unset;
                                padding: unset;
                                border: unset;
                                border-radius: unset;
                                width: 100%;
                            }
                        }
                    }
                }
                .options-wrapper{
                    display: flex;
                    align-items: center;
                    .options{
                        margin-left: 2rem;
                    }
                }
            }
            &.mobile{
                display: none;
                &.active{
                    background: #000;
                    backdrop-filter: blur(8px);
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0 1rem;
                    ul{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        max-height: 70vh;
                        overflow-y: auto;
                        li{
                            margin-right: unset;
                            padding: .625rem 0;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border-bottom: solid 1px #222;
                            &.nav-item {
                                padding: unset;
                                .nav-item_title {
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                }
                                p,a {
                                    padding: 0.625rem 0;
                                }
                            }
                            &:first-child {
                                border-top: solid 1px #222;
                            }
                            &:last-child{
                                margin-bottom: unset;
                            }
                            a,p{
                                font-size: 1.025rem;
                            }
                            @include media-breakpoint-down(lg) {
                                flex-direction: column;
                                align-items: flex-start;
                            }
                        }
                    }
                }
            }
        }
        .leading, .trailing{
            position: relative;
            z-index: 1;
            display: flex;
            flex-grow: 1;
            flex-basis: 0;
        }
        .leading{
            img{
                &:last-child{
                    display: none;
                }
            }
        }
        .trailing{
            justify-content: flex-end;
            align-items: center;
            position: relative;
            .config-wrapper{
                display: flex;
                align-items: center;
                &:hover{
                    .dropdown-wrapper{
                        max-height: 50vh;
                    }
                    .config{
                        i{
                            transform: rotate(180deg);
                        }
                    }
                }
                .config{
                    margin-right: 1rem;
                    color: #fff;
                    text-transform: uppercase;
                    display: flex;
                    align-items: center;
                    p{
                        font-weight: 700;
                        font-size: .75rem;
                        margin-bottom: unset;
                        margin-right: .5rem;
                        line-height: 1rem;
                    }
                    i{
                        transition: transform .4s ease-in-out;
                    }
                    .wrapper{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        p{
                            font-size: .75rem;
                            margin: unset;
                            font-weight: 500;
                        }
                        i{
                            font-size: .8rem;
                        }
                    }
                }
            }
            .cart{
                i{
                    font-size: 1.25rem;
                    color: #fff;
                }
            }
            .menu{
                display: none;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                align-items: center;
                justify-content: center;
                margin-left: 1rem;
                height: 2rem;
                width: 1.5rem;
                .line{
                    position: relative;
                    width: 100%;
                    height: .15rem;
                    background: #fff;
                    margin-bottom: .4rem;
                    transition: all .2s ease-in-out;
                    &:last-child{
                        margin-bottom: unset;
                    }
                }
                &.active{
                    .line{
                        transform: rotate(45deg);
                        margin-bottom: unset;
                        top: .075rem;
                        &:last-child{
                            top: -.075rem;
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
        }
    }
}
li.nav-item {
    position: relative;
}
@include media-breakpoint-down(xl) {
    header{
        .wrapper{
            .leading{
                flex-grow: unset;
            }
            .trailing{
                flex-grow: unset;
            }
        }
    }
}
@include media-breakpoint-down(lg) {
    header{
        .wrapper{
            nav{
                display: none;
            }
            .trailing{
                 .config-wrapper{
                    .config{
                        display: none;
                    }
                    &:hover{
                        .dropdown{
                            display: none;
                        }
                    }
                }
                .menu{
                    display: flex;
                }
            }
        }
    }
}
@include media-breakpoint-down(md){
    header{
        #headline {
            p {
                font-size: .625rem;
            }
        }
        .wrapper{
            .leading{
                img{
                    &:first-child{
                        display: none;
                    }
                    &:last-child{
                        display: flex;
                    }
                }
            }
        }
    }
}
</style>
