<template>
   <section id="fact">
      <div class="container-fluid">
         <div class="row justify-content-between parent">
            <div class="col-lg-6 image">
              <slot name="image"></slot>
            </div>
            <div class="col-lg-6 content">
               <p class="highlight"><slot name="tag"></slot></p>
               <h2><slot name="title"></slot></h2>
               <div class="row justify-content-end">
                  <div class="wrapper col-md-10">
                     <p><slot name="desc"></slot></p>
                     <!-- <gn-button>
                        Customize
                     </gn-button> -->
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
section#fact{
    &:last-of-type{
        margin-bottom: unset;
    }
    .parent{
       flex-direction: row-reverse;
      .content{
         display: flex;
         flex-direction: column;
         justify-content: center;
         max-width: 48rem;
         position: relative;
         left: 0;
         h2{
               margin-bottom: 4rem;
         }
         p{
               color: #8E8E8E;
               margin-bottom: 2rem;
         }
         p.highlight{
               color: $accent;
               margin-bottom: 1rem;
         }
      }
      .image{
         position: relative;
         margin-top: 8rem;
      }
      img{
         width: 100%;
      }
    }
    &:nth-of-type(even){
       .parent{
          flex-direction: row;
       }
    }
}
@include media-breakpoint-down(lg){
   section#fact{
      margin-bottom: 6rem;
      .parent{
         flex-direction: column-reverse;
         .image{
            margin-top: 3rem;
         }
      }
      &:nth-of-type(even){
       .parent{
          flex-direction: column-reverse;
       }
    }
   }
}
</style>
