<template>
   <section id="content-hero">
      <div class="container-fluid">
         <div class="row justify-content-between">
            <div class="col-lg-5 title">
               <p class="highlight">Our Content</p>
               <h2>We are Gamenetics, helping fuel the gaming revolution.</h2>
            </div>
            <div class="col-lg-6 socials">
               <a href="google.co.uk"><i class="fab fa-twitter"></i></a>
               <a href="google.co.uk"><i class="fab fa-twitter"></i></a>
               <a href="google.co.uk"><i class="fab fa-twitter"></i></a>
               <a href="google.co.uk"><i class="fab fa-twitter"></i></a>
            </div>
         </div>
      </div>
   </section>
   <section id="filters">
      <div class="container-fluid">
         <div class="row justify-content-between">
            <div class="col-lg-6 buttons">
               <div class="tags-wrapper">
                  <p class="highlight">FILTER BY PLATFORMS</p>
                  <div class="social-tags">
                     <div :class="[filteredPlatforms.includes(platform.slug) ? 'active' : '', 'social-tag']" v-for="platform in platforms" :key="platform.slug" @click="togglePlatform(platform.slug)">{{ platform.name }}</div>
                  </div>
               </div>
               <div class="tags-wrapper">
                   <p class="highlight">FILTER BY TAGS</p>
                  <div class="filter-tags">
                     <div :class="[!filteredTags.length ? 'active' : '', 'filter-tag']" @click="toggleTag(null)">All</div>
                     <div :class="[filteredTags.includes(tag.slug) ? 'active' : '', 'filter-tag']" v-for="tag in tags" :key="tag.slug" @click="toggleTag(tag.slug)">{{ tag.name }}</div>
                  </div>
               </div>
            </div>
            <div class="col-lg-5 search">
               <p class="highlight">FILTER BY SEARCH</p>
               <input type="search" placeholder="Search..." v-model="search">
            </div>
         </div>
      </div>
   </section>
   <section id="content">
      <div class="container-fluid">
         <div class="wrapper">
            <div class="row">
               <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 individual" v-for="post in filteredPosts" :key="post.id">
                  <individual-content :post="post"/>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data(){
        return {
            filteredPlatforms: ['youtube','instagram','tiktok'],
            filteredTags: [],
            search: ''
        }
    },
    computed: {
        ...mapState(['posts']),
        platforms(){
            if(this.posts.length){
                let allPlatforms = this.posts.flatMap(({platform}) => platform);
                let platforms = [...new Set(allPlatforms.map((ap) => JSON.stringify(ap))),].map((p) => JSON.parse(p));
                return _.orderBy(platforms, 'sorting');
            }else{
                return [];
            }
        },
        tags(){
            if(this.posts.length){
                let allTags = this.posts.flatMap(({tags}) => tags);
                let tags = [...new Set(allTags.map((at) => JSON.stringify(at))),].map((t) => JSON.parse(t));
                return _.orderBy(tags, 'sorting');
            }else{
                return [];
            }
        },
        filteredPosts(){
            return this.posts.filter(post => {
                return this.filteredPlatforms.includes(post.platform.slug);
            }).filter(post => {
                let tags = this.filteredTags;
                return tags.length ? post.tags.some(tag => tags.includes(tag.slug)) : post;
            }).filter(post => {
                if(this.search != ''){
                    if(post.title != null){
                        return post.title.toLowerCase().includes(this.search.toLowerCase());
                    }
                }else{
                    return post;
                }
            })
        },
    },
    methods: {
        togglePlatform(platform) {
            if(this.filteredPlatforms.includes(platform)){
                this.filteredPlatforms = this.filteredPlatforms.filter(p => p !== platform);
            }else{
                this.filteredPlatforms.push(platform);
            }
        },
        toggleTag(tag) {
            if(tag){
                if(this.filteredTags.includes(tag)){
                    this.filteredTags = this.filteredTags.filter(t => t !== tag);
                }else{
                    this.filteredTags.push(tag);
                }
            }else{
                this.filteredTags = [];
            }
        }
    },
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
section#content-hero{
   padding-top: 8rem;
   margin-bottom: 4rem;
   .socials{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-top: 2.5rem;
      a{
         background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
         border: 1px solid #262626;
         backdrop-filter: blur(20px);
         border-radius: .5rem;
         height: 4rem;
         width: 4rem;
         display: flex;
         align-items: center;
         justify-content: center;
         text-decoration: unset;
         margin-right: 2rem;
         &:last-child{
            margin-right: unset;
         }
         i{
            font-size: 1.5rem;
            line-height: 1.5rem;
         }
      }
   }
}
section#filters{
   margin-bottom: 4rem;
   .tags-wrapper{
      .social-tags{
       touch-action: manipulation;
       user-select: none;
       -webkit-user-select: none;
       -moz-user-select: none;
       -ms-user-select: none;
         display: flex;
         align-items: center;
         margin-bottom: .5rem;
         .social-tag{
            background: #191919;
            margin-right: .5rem;
            padding: .45rem 1rem;
            color: white;
            font-size: .7rem;
            letter-spacing: .06rem;
            text-transform: uppercase;
            font-weight: 600;
            border-radius: .4rem;
            transition: background .2s ease-in-out;
            touch-action: manipulation;
            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            cursor: pointer;
            &:last-child{
               margin-right: unset;
            }
            &.active{
               background:red;
            }
            &:hover{
               background: #333;
               &.active{
                  background:red;
               }
               &:hover{
                  background: #333;
                  &.active{
                     background: red;
                  }
               }
            }
         }
      }
      .filter-tags{
         display: flex;
         align-items: center;
         .filter-tag{
            background: #191919;
            margin-right: .5rem;
            padding: .45rem 1rem;
            color: white;
            font-size: .7rem;
            letter-spacing: .06rem;
            text-transform: uppercase;
            font-weight: 600;
            border-radius: .4rem;
            transition: background .2s ease-in-out;
            touch-action: manipulation;
            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            cursor: pointer;
            &:last-child{
               margin-right: unset;
            }
            &.active{
               background: $accent;
            }
            &:hover{
               background: #333;
               &.active{
                  background: $accent;
               }
            }
         }
      }
   }
   .search{
       p.highlight{
         margin-bottom: .5rem;
         color: #8E8E8E;
         font-size: .6rem;
      }
      input{
         padding: .5rem 1rem;
         width: 100%;
         background: #090909;
         color: #fff;
         border-radius: .5rem;
         border: solid 1px #2F2F2F;
         outline: unset;
         &::placeholder{
            color: #474747;
         }
      }
   }
}
section#content{
   margin-bottom: 5rem;
   .wrapper{
      padding: 2rem;
      padding-bottom: unset;
      background: linear-gradient(135deg, rgba(24, 24, 24, 0.5) 0%, rgba(0, 0, 0, 0.1) 100%);
      border-radius: 10px;
      border: solid 1px #222222;
      .individual{
         display: flex;
         flex-direction: column;
      }
   }
}
</style>
