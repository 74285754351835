<template>
    <div class="selector">
        <div class="title">
            <div class="text-wrapper">
                <h3>{{ option.name }}</h3>
                <p>{{ config.selection.name }} <template v-if="!config.selection.default">+{{ value }}</template></p>
            </div>
            <div class="select-wrapper">
                <i class="fas fa-caret-down"></i>
                <select v-model="collection">
                    <option value="null">All Colors</option>
                    <option v-for="collection in collections" :key="collection.slug" :value="collection.slug">{{ collection.name }}</option>
                </select>
            </div>
        </div>
        <div :class="[isLarge ? 'large' : '', 'variants row row-cols-5']">
            <swatch v-for="swatch in paginatedSwatches" :key="swatch.id" :option="option" :swatch="swatch" :selected="swatch.id == config.selection.id" @click="editConfig([option.id,swatch.id])" />
        </div>
    </div>
    <div class="navigation" v-if="pages > 1">
        <div :class="[previousButtonDisabled ? 'disabled' : '', 'arrow left']" @click="pageChange('previous')">
            <i class="far fa-chevron-left"></i>
        </div>
        <ul>
            <li v-for="(i, dots) in pages" :key="dots" @click="pageChange(i-1)">
                <div :class="[i-1 == page ? 'active' : '', 'dot']"></div>
            </li>
        </ul>
        <div :class="[nextButtonDisabled ? 'disabled' : '', 'arrow right']" @click="pageChange('next')">
            <i class="far fa-chevron-right"></i>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    props: ['option'],
    data(){
        return {
            collection: null,
            limit: 10,
            page: 0
        }
    },
    computed: {
        ...mapState(['config']),
        swatches(){
            var vm = this;
            return this.$store.state.swatches.length ? this.$store.state.swatches.filter(function(s){ return s.swatchable_type == "option" && s.swatchable_id == vm.option.id; }) : [];
        },
        config(){
            return this.$store.state.config[this.option.slug];
        },
        value(){
            return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            }).format(this.config.value);
        },
        collections(){
            if(this.swatches.length){
                let allCollections = this.swatches.flatMap(({collection}) => collection);
                // console.log(allCollections);
                let collections = [...new Set(allCollections.map((o) => JSON.stringify(o))),].map((string) => JSON.parse(string));
                // console.log(collections);
                return _.orderBy(collections, 'sorting');
            }else{
                return [];
            }
        },
        filteredSwatches(){
            if(this.swatches.length){
                if(this.collection != null && this.collection != 'null'){
                    return this.swatches.filter(swatch => {
                        return swatch.collection.slug == this.collection;
                    })
                }else{
                    return this.swatches;
                }
            }else{
                return [];
            }
        },
        pages(){
            return Math.ceil(this.filteredSwatches.length / parseInt(this.limit));
        },
        offset(){
            return this.page * parseInt(this.limit)
        },
        paginatedSwatches(){
            const start = this.offset,
                  end = start + parseInt(this.limit);
            return this.filteredSwatches.slice(start, end);
        },
        previousButtonDisabled() {
            return this.page <= 0
        },
        nextButtonDisabled(){
            return this.page === this.pages-1
        },
        isLarge(){
            return this.swatches.length > this.limit / 2 ? true : false;
        }
    },
    mounted(){
    },
    methods: {
        ...mapActions([
            'editConfig'
        ]),
        pageChange(page) {
            if(page == 'next'){
                if(!this.nextButtonDisabled){
                    this.page += 1;
                }
            }else if(page == 'previous'){
                if(!this.previousButtonDisabled){
                    this.page -= 1;
                }
            }else{
                this.page = page;
            }
        }
    },
    watch: {
        collection: function() {
            if(this.page > 0) {
                this.page = 0
            }
        }
    }
}

</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.selector{
    .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-items: flex-start;
        margin-bottom: 2rem;
        .text-wrapper{
            h3{
                font-size: 1rem;
                margin-bottom: unset;
            }
            p{
                margin: unset;
                color: $highlight;
                font-size: .8rem;
            }
        }
        .select-wrapper{
            position: relative;
            i{
                position: absolute;
                right: .75rem;
                top: 50%;
                transform: translateY(-50%);
                font-size: .85rem;
            }
            select{
                background: $accent;
                padding: .45rem 1.75rem .45rem 1rem;
                color: white;
                font-size: .7rem;
                letter-spacing: .06rem;
                text-transform: uppercase;
                font-weight: 600;
                border-radius: .2rem;
                appearance: none;
                outline: unset;
                border: unset;
                option{
                    padding-right: .5rem;
                }
            }

        }
    }
    .variants{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        &.large{
            height: 14rem;
        }
    }
}
.navigation{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ul{
        margin: unset;
        padding: unset;
        list-style-type: none;
        display: flex;
        li{
            cursor: pointer;
            padding: .25rem;
            .dot{
                height: .6rem;
                width: .6rem;
                background: #191919;
                border-radius: 50%;
                margin-right: .5rem;
                transition: background .2s ease-in-out;
                &:last-child{
                    margin-right: unset;
                }
                &.active{
                    background: $accent;
                }
            }
            &:hover{
                .dot{
                    background: #333333;
                    &.active{
                        background: $accent;
                    }
                }
            }
        }
    }
    .arrow{
        cursor: pointer;
        height: 2rem;
        width: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
        border: 1px solid #222;
        border-radius: .4rem;
        &.disabled{
            cursor: unset;
            i{
                color: #454545;
            }
        }
        &.left{
            transition: transform .3s ease;
            &:hover{
                transform: translateX(-.25rem);
            }
        }
        &.right{
            transition: transform .3s ease;
            &:hover{
                transform: translateX(.25rem);
            }
        }
    }
}
@include media-breakpoint-down(md){
    .selector{
        .title{
            margin-bottom: 1rem;
            .text-wrapper{
                h3{
                    font-size: .8rem;
                }
            }
            .select-wrapper{
                select{
                    font-size: .6rem;
                }
            }
        }
    }
}
</style>
