<template>
    <div :class="[icon ? 'icon' : '', 'dropdown']">
        <div class="leading"></div>
        <div class="wrapper">
            <select class="middle">
                <slot></slot>
            </select>
            <i :class="icon" v-if="icon"></i>
        </div>
        <div class="trailing"></div>
    </div>
</template>

<script>
export default {
    props: ['icon']
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.dropdown{
    display: flex;
    cursor: pointer;
    .leading, .middle, .trailing{
        transition: background 0.2s;
        background: $accent;
        position: relative;
    }
    .leading{
        width: .5rem;
        clip-path: polygon(100% 0, 0 50%, 100% 100%);
    }
    .wrapper{
        position: relative;
        .middle{
            padding: .45rem 1rem .45rem 1rem;
            color: white;
            font-size: .75rem;
            letter-spacing: .06rem;
            text-transform: uppercase;
            font-weight: 600;
            border: unset;
            outline: unset;
            appearance: unset;
            border-radius: unset;
        }
        i{
            position: absolute;
            font-size: .9rem;
            left: .8rem;
            top: .55rem;
            color: #fff;
        }
    }
    .trailing{
        right: .3px;
        width: .5rem;
        clip-path: polygon(0 0, 100% 50%, 0 100%);
    }
    &:hover{
        .leading, .middle, .trailing{
            background: darken($accent, 10%);
        }
    }
    &.icon{
        .middle{
            padding-left: 2.5rem;
        }
    }
}
</style>
