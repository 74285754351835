<template>
  <ul>
      <li>
          Concave
      </li>
      <li class="active">
          Domed
      </li>
  </ul>
    <!-- todo @Ben if you just leave some padding, I'll plug the swatches in. -->
    <!-- <swatch /> -->
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
ul{
    margin: unset;
    padding: unset;
    display: flex;
    align-items: center;
    list-style-type: none;
    flex-wrap: wrap;
    li{
        background: #1A1A1A;
        padding: .45rem 1rem;
        color: white;
        font-size: .6rem;
        letter-spacing: .06rem;
        text-transform: uppercase;
        font-weight: 600;
        border-radius: .4rem;
        margin-right: .4rem;
        transition: background .3s ease-in-out;
        &:last-child{
            margin-right: unset;
        }
        &.active{
            background: $accent;
        }
    }
}
</style>
